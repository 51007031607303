import { Navigate, Route, Routes } from "react-router-dom";
import { Admin } from "../../pages/Admin/Admin";
import { CreateShop } from "../../pages/Admin/CreateShop";
import { DuplicateShop } from "../../pages/Admin/DuplicateShop";
import { EditShop } from "../../pages/Admin/EditShop";
import { LightJobList } from "../../pages/Admin/LightJobList";
import { OrderJson } from "../../pages/Admin/OrderJson";
import { OrderList } from "../../pages/Admin/OrderList";
import { ProcessList } from "../../pages/Admin/ProcessList";
import { ShopList } from "../../pages/Admin/ShopList";
import { Auth } from "../../pages/Auth/Auth";
import { ResellerList } from "../../pages/Admin/ResellerList";
import { CreateReseller } from "../../pages/Admin/CreateReseller";

const Navigation = () => {
  return (
    <Routes>
      {/* Routes autenticazione */}
      <Route path="/login" element={<Auth />} />

      {/* Routes admin */}
      <Route path="/" element={<Admin />}>
        <Route index element={<ShopList />} />
        <Route path="shop/:id" element={<EditShop />} />
        <Route path="shop/:id/lightorders" element={<LightJobList />} />
        <Route path="shop/:id/orders" element={<OrderList />} />
        <Route path="shop/:id/orders/:jobId" element={<OrderJson />} />
        <Route path="duplicate/:id" element={<DuplicateShop />} />
        <Route path="newshop" element={<CreateShop />} />
        <Route path="processes" element={<ProcessList />} />
        <Route path="resellers" element={<ResellerList />} />
        <Route path="resellers/new" element={<CreateReseller />} />
      </Route>

      {/* Redirect alla prima pagina */}
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};

export { Navigation };
