import { GraphQLError } from "graphql";

import { Observable } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { LOG } from "../../config";

const log = LOG.extend("APOLLOCLIENT");

// Creazione middleware per gestione autenticazione e refresh tokens

export type FetchNewAccessToken = (
  refreshToken: string
) => Promise<
  { accessToken: string; refreshToken: string | undefined } | undefined
>;

export type IsUnauthenticatedError = (
  graphQLError: GraphQLError,
  operationName: string
) => boolean;

export type GetToken = () => string | undefined | null;

export type IsAccessTokenValid = (accessToken?: string | null) => boolean;

export type OnSuccessfulRefetch = (tokens: {
  accessToken: string;
  refreshToken: string | undefined;
}) => void;

export type OnFailedRefresh = (doLogout: boolean, error: any) => void;

interface Options {
  isUnauthenticatedError: IsUnauthenticatedError;
  getAccessToken: GetToken;
  getRefreshToken: GetToken;
  isAccessTokenValid: IsAccessTokenValid;
  fetchNewAccessToken: FetchNewAccessToken;
  authorizationHeaderKey: string;
  onSuccessfulRefresh?: OnSuccessfulRefetch;
  onFailedRefresh?: OnFailedRefresh;
}

export const onErrorRefreshTokenLinkCreator = ({
  isUnauthenticatedError,
  getAccessToken,
  getRefreshToken,
  isAccessTokenValid,
  fetchNewAccessToken,
  authorizationHeaderKey,
  onSuccessfulRefresh,
  onFailedRefresh,
}: Options) =>
  onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
      for (let i = 0; i < graphQLErrors.length; i += 1) {
        const graphQLError = graphQLErrors[i];

        if (isUnauthenticatedError(graphQLError, operation.operationName)) {
          const accessToken = getAccessToken();
          const refreshToken = getRefreshToken();

          if (!refreshToken) {
            // DA CONTINUARE O ERRORE?
            let error =
              operation.operationName +
              " | Error fetch new access token, NO REFRESH TOKEN";
            let doLogout = true;
            onFailedRefresh && onFailedRefresh(doLogout, error);
            return forward(operation);
          }

          if (isAccessTokenValid(accessToken)) {
            // FARE LOGOUT O NO?
            let error =
              operation.operationName +
              " | Error fetch new access token, ACCESS TOKEN STILL VALID";
            let doLogout = false;
            onFailedRefresh && onFailedRefresh(doLogout, error);
            return forward(operation);
          }

          return new Observable((observer) => {
            fetchNewAccessToken(refreshToken)
              .then((tokens) => {
                if (!tokens?.accessToken) {
                  let error =
                    operation.operationName +
                    " | Error fetch new access token, NO ACCESS TOKEN";
                  let doLogout = true;
                  onFailedRefresh && onFailedRefresh(doLogout, error);
                  return null;
                }

                operation.setContext(({ headers = {} }: any) => ({
                  headers: {
                    ...headers,
                    [authorizationHeaderKey]: tokens.accessToken || undefined,
                  },
                }));

                onSuccessfulRefresh && onSuccessfulRefresh(tokens);
              })
              .then(() => {
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                };

                forward(operation).subscribe(subscriber);
              })
              .catch((e) => {
                let error =
                  operation.operationName +
                  " | Error fetch new access token, FETCH FAILED";
                let doLogout = false;
                onFailedRefresh && onFailedRefresh(doLogout, error);
                observer.error(error);
              });
          });
        } else {
          log.error(
            operation.operationName +
              " | REQUEST ERRORS: " +
              JSON.stringify(graphQLError)
          );
          return;
        }
      }
    }

    return forward(operation);
  });
