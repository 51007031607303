import { Center, Spinner } from "@chakra-ui/react";

const LoadingPage = () => {
  return (
    <Center w="100%" height="100vh">
      <Spinner
        speed="0.6s"
        emptyColor="purple.200"
        color="purple.400"
        size="lg"
      />
    </Center>
  );
};

export { LoadingPage };
