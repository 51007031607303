import { Center, Flex, Text, Card } from "@chakra-ui/react";
import { ReactNode } from "react";

interface ErrorTextProps {
  children: ReactNode;
  position: "insideElement" | "fullPage";
}
const ErrorText = ({ children, position }: ErrorTextProps) => {
  if (position === "insideElement") {
    return (
      <Flex
        w="100%"
        h="100%"
        direction="column"
        align={"center"}
        justify={"center"}
      >
        <Text align={"center"} color={"tomato"} fontWeight={"bold"}>
          {children}
        </Text>
      </Flex>
    );
  }

  if (position === "fullPage") {
    return (
      <Center position="absolute" w="100%" height="100vh">
        <Card w="90%" maxW="md" minH={20} bg="white" justify={"center"}>
          <Text align={"center"} color={"tomato"} fontWeight={"bold"}>
            {children}
          </Text>
        </Card>
      </Center>
    );
  }

  return null;
};

export { ErrorText };
