import { gql } from "@apollo/client";

import { Product, PRODUCT_FRAGMENT } from "./product";
import { UserBasicInfoAddress } from "./job";

type ShopStatus = "running" | "copying" | "removing";

interface Address {
  country: "IT";
  city: string;
  state: string;
  zipCode: string;
  phoneNumber: string;
  street: string;
  denomination?: string;
}

interface ShopAddresses {
  commercial: Address[];
  legal: Address;
}

interface ShopPaymentMethod {
  onSite: {
    isEnabled: boolean;
  };
  stripe: {
    isEnabled: boolean;
    stripeInfo?: {
      accountId: string;
    };
  };
}

interface ShopServicePayments {
  earning: number | null;
  startDate: string | null; // Date ISO Format es: "2021-03-01T00:00:00.000Z"
  endDate: string | null; // Date ISO Format es: "2021-03-01T00:00:00.000Z"
  credits: number | null;
  annualFee: number | null;
  actualPercentualFee: number | null;
  fees: {
    earningLimit: number | null;
    percentualFee: number | null;
  }[];
}

type DisplayType = "block" | "list_horizontal";

interface ProductTreeLvl {
  type: "folder" | "product";
  name: string | null;
  description: string | null;
  isSponsored: boolean | null;
  isVisible: boolean | null;
  picture: {
    url: string | null;
    _id: string | null;
  } | null;
  style: {
    color: string | null;
    display?: DisplayType;
  };
  product: Product | null;
  next: ProductTreeLvl[];
}

interface Shop {
  _id: string;
  name: string;
  owner: {
    _id: string;
    email: string;
    username: string;
    basicInfo: {
      name: string;
      addresses: {
        home: UserBasicInfoAddress;
        work: UserBasicInfoAddress;
        other: UserBasicInfoAddress;
      };
    };
  };
  type: "light" | "full";
  isEnabled: boolean;
  isAccepted: boolean;
  isMaintenance: boolean;
  code: string;
  status: ShopStatus;
  servicePayments: ShopServicePayments;
  vat: string | null;
  deliveryInfo: {
    atHome: {
      allowedPaymentTypes: {
        onSite: boolean;
        stripe: boolean;
      };
      timings: {
        country: string;
        time: number | null;
      }[];
      costs: {
        country: string;
        cost: number | null;
      }[];
    };
    atShop: {
      allowedPaymentTypes: {
        onSite: boolean;
        stripe: boolean;
      };
      time: number | null;
      cost: number | null;
    };
  };
  addresses: ShopAddresses;
  productTree: ProductTreeLvl[];
  paymentMethods: ShopPaymentMethod;
  reseller: {
    _id: string;
    name: string;
  };
}

const ADDRESS_FRAGMENT = gql`
  fragment addressFragment on ShopBusinessAddress {
    country
    city
    state
    zipCode
    phoneNumber
    street
    denomination
  }
`;

const SERVICE_PAYMENT_FRAGMENT = gql`
  fragment paymentFragment on ShopServicePayments {
    earning
    startDate
    endDate
    credits
    annualFee
    actualPercentualFee
    fees {
      earningLimit
      percentualFee
    }
  }
`;

const SHOP_FRAGMENT = gql`
  ${ADDRESS_FRAGMENT}
  ${SERVICE_PAYMENT_FRAGMENT}
  fragment shopFragment on Shop {
    _id
    name
    owner {
      _id
      username
      email
      basicInfo {
        name
      }
    }
    type
    isEnabled
    isAccepted
    isMaintenance
    code
    status
    vat
    reseller {
      _id
      name
    }
    servicePayments {
      ...paymentFragment
    }
    addresses {
      commercial {
        ...addressFragment
      }
      legal {
        ...addressFragment
      }
    }
    paymentMethods {
      onSite {
        isEnabled
      }
      stripe {
        isEnabled
        #stripeInfo {
        #  accountId
        #}
      }
    }
    deliveryInfo {
      atShop {
        allowedPaymentTypes {
          onSite
          stripe
        }
        time
        cost
      }
      atHome {
        allowedPaymentTypes {
          onSite
          stripe
        }
        timings {
          country
          time
        }
        costs {
          country
          cost
        }
      }
    }
  }
`;

const SHOP_MIN_FRAGMENT = gql`
  fragment shopMinFragment on Shop {
    _id
    name
    code
    owner {
      _id
      email
      username
      basicInfo {
        name
      }
    }
    type
    isEnabled
    isAccepted
    status
    isMaintenance
    servicePayments {
      earning
    }
  }
`;

const SHOPS = gql`
  ${SHOP_MIN_FRAGMENT}
  query SHOPS {
    Shops {
      ...shopMinFragment
    }
  }
`;

const SHOP = gql`
  ${SHOP_FRAGMENT}
  query SHOP($_id: ID!) {
    Shop(_id: $_id) {
      ...shopFragment
    }
  }
`;

const SHOP_NAME = gql`
  query SHOP_NAME($_id: ID!) {
    Shop(_id: $_id) {
      name
    }
  }
`;

/* --------------------------------------------------------------------------*/
/* ----------------------  MUTATION  ----------------------------------------*/
/* --------------------------------------------------------------------------*/

const SHOP_EDIT = gql`
  ${SHOP_FRAGMENT}
  mutation SHOP_EDIT($_id: ID!, $shop: RootShopEditInput!) {
    ShopEdit(_id: $_id, shop: $shop) {
      ...shopFragment
    }
  }
`;

interface ShopEditData {
  ShopEdit: Shop;
}

interface ShopEditVariables {
  _id: string;
  shop: {
    name?: string;
    isEnabled?: boolean;
    isAccepted?: boolean;
    isMaintenance?: boolean;
    vat?: string;
    deliveryInfo?: Shop["deliveryInfo"];
    addresses?: Shop["addresses"];
    paymentMethods?: Shop["paymentMethods"];
    reseller?: string; // resellerId
  };
}

// ---------------------------------------------------------

const CREATE_SHOP = gql`
  ${SHOP_FRAGMENT}
  mutation CREATE_SHOP($owner: OwnerInput!, $shop: ShopInput!) {
    createShop(owner: $owner, shop: $shop) {
      ...shopFragment
    }
  }
`;

interface CreateShopData {
  createShop: Shop;
}

interface CreateShopVariables {
  owner: {
    email: string;
    basicInfo: {
      name: string;
    };
  };
  shop: {
    name: string;
    vat: string;
    type: Shop["type"];
    addresses: Shop["addresses"];
  };
}

// ---------------------------------------------------------

const SHOP_UPDATE_CREDITS = gql`
  ${SHOP_FRAGMENT}
  mutation SHOP_UPDATE_CREDITS($_id: ID!, $credits: Float!) {
    RootShopServicePaymentUpdateCredit(_id: $_id, credits: $credits) {
      ...shopFragment
    }
  }
`;

interface ShopUpdateCreditsData {
  RootShopServicePaymentUpdateCredit: Shop;
}

interface ShopUpdateCreditsVariables {
  _id: string;
  credits: number;
}

// ---------------------------------------------------------

const SHOP_UPDATE_PERIOD = gql`
  ${SHOP_FRAGMENT}
  mutation SHOP_UPDATE_PERIOD($_id: ID!, $startDate: Date, $endDate: Date) {
    RootShopServicePaymentUpdatePeriod(
      _id: $_id
      startDate: $startDate
      endDate: $endDate
    ) {
      ...shopFragment
    }
  }
`;

interface ShopUpdatePeriodData {
  RootShopServicePaymentUpdatePeriod: Shop;
}

interface ShopUpdatePeriodVariables {
  _id: string;
  startDate?: string; // Date ISO Format es: "2021-03-01T00:00:00.000Z";
  endDate?: string; // Date ISO Format es: "2021-03-01T00:00:00.000Z";
}

// ---------------------------------------------------------

const SHOP_UPDATE_FEES = gql`
  ${SHOP_FRAGMENT}
  mutation SHOP_UPDATE_FEES($_id: ID!, $fees: [ShopFeeInput]) {
    RootShopServicePaymentUpdateFees(_id: $_id, fees: $fees) {
      ...shopFragment
    }
  }
`;

interface ShopUpdateFeesData {
  RootShopServicePaymentUpdateFees: Shop;
}

interface ShopUpdateFeesVariables {
  _id: string;
  fees: Shop["servicePayments"]["fees"];
}

// ---------------------------------------------------------

const DUPLICATE_SHOP = gql`
  mutation DUPLICATE_SHOP(
    $_id: ID!
    $owner: OwnerInputForDuplication!
    $shop: ShopInputForDuplication!
  ) {
    RootShopDuplicate(_id: $_id, owner: $owner, shop: $shop) {
      _id
      code
    }
  }
`;

interface DuplicateShopData {
  RootShopDuplicate: {
    _id: string;
    code: string;
  };
}

interface DuplicateShopVariables {
  _id: string;
  owner: {
    email: string;
    basicInfo: {
      name: string;
    };
  };
  shop: {
    name: string;
    vat: string;
    addresses: Shop["addresses"];
  };
}

// ---------------------------------------------------------

const SHOP_CHANGE_TYPE = gql`
  ${SHOP_FRAGMENT}
  mutation SHOP_CHANGE_TYPE($_id: ID!, $type: ShopType!) {
    RootShopChangeType(_id: $_id, type: $type) {
      ...shopFragment
    }
  }
`;

interface ShopChangeTypeData {
  RootShopChangeType: Shop;
}

interface ShopChangeTypeVariables {
  _id: string;
  type: Shop["type"];
}

// ---------------------------------------------------------

export {
  SHOPS,
  SHOP,
  SHOP_NAME,
  SHOP_EDIT,
  SHOP_UPDATE_CREDITS,
  SHOP_UPDATE_PERIOD,
  SHOP_UPDATE_FEES,
  CREATE_SHOP,
  DUPLICATE_SHOP,
  SHOP_CHANGE_TYPE,
};
export type {
  Shop,
  ShopEditData,
  ShopEditVariables,
  ShopUpdateCreditsData,
  ShopUpdateCreditsVariables,
  ShopUpdatePeriodData,
  ShopUpdatePeriodVariables,
  ShopUpdateFeesData,
  ShopUpdateFeesVariables,
  CreateShopData,
  CreateShopVariables,
  DuplicateShopData,
  DuplicateShopVariables,
  ShopChangeTypeData,
  ShopChangeTypeVariables,
};
