import { useMutation } from "@apollo/client";
import {
  Box,
  Card,
  CardHeader,
  CardBody,
  Heading,
  SimpleGrid,
  Button,
  Flex,
} from "@chakra-ui/react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ErrorText, FormInputText } from "../../../components/Ui";
import { LOG } from "../../../config";
import {
  RESELLER_CREATE,
  ResellerCreateData,
  ResellerCreateVariables,
} from "../../../stores/queries/reseller";

const log = LOG.extend("CREATE_RESELLER");

interface Inputs {
  name: string;
  managerName: string;
  managerPhone: string;
  managerEmail: string;
  phoneNumber: string;
  email: string;
  pec: string;
  commission: string;
  // address
  street: string;
  city: string;
  zipCode: string;
  state: string;
  country: string;
  vat: string;
  sdi: string;
}

const CreateResellerDetail = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>({
    defaultValues: {
      name: "",
      managerName: "",
      managerPhone: "",
      managerEmail: "",
      phoneNumber: "",
      email: "",
      pec: "",
      commission: "",
      // address
      street: "",
      city: "",
      zipCode: "",
      state: "",
      country: "Italy",
      vat: "",
      sdi: "",
    },
  });

  const [
    createReseller,
    { error: createResellerError, loading: createResellerLoading },
  ] = useMutation<ResellerCreateData, ResellerCreateVariables>(RESELLER_CREATE);

  const onSubmit: SubmitHandler<Inputs> = async (inputs) => {
    console.log(inputs);
    if (isNaN(Number(inputs.commission))) {
      log.error(" - commission is not a number");
      return;
    }

    const { data } = await createReseller({
      variables: {
        reseller: {
          name: inputs.name,
          manager: {
            name: inputs.managerName,
            phone: inputs.managerPhone,
            email: inputs.managerEmail,
          },
          phone: inputs.phoneNumber,
          email: inputs.email,
          pec: inputs.pec,
          address: {
            country: "IT",
            city: inputs.city,
            state: inputs.state,
            zipCode: inputs.zipCode,
            street: inputs.street,
            vat: inputs.vat,
            sdi: inputs.sdi,
          },
          commission: Number(inputs.commission),
        },
      },
    });

    if (!data?.ResellerCreate) {
      log.error(" - createReseller mutation - something went wrong");
      return;
    }

    log.info(
      `Created Shop: _id: ${data.ResellerCreate._id} - name: ${data.ResellerCreate.name}`
    );

    reset();
    navigate(-1);
  };

  const onCancel = () => {
    reset();
    navigate(-1);
  };

  const renderEditButton = () => {
    return (
      <Flex direction={"row"} justify="end" mt={8}>
        <Button
          onClick={onCancel}
          colorScheme={"red"}
          mr={3}
          isLoading={createResellerLoading || isSubmitting}
          variant={"outline"}
        >
          Annulla
        </Button>
        <Button
          colorScheme="teal"
          type="submit"
          isLoading={createResellerLoading || isSubmitting}
          variant={"outline"}
        >
          Crea
        </Button>
      </Flex>
    );
  };

  return (
    <Box w="100%">
      <Card w="100%" h="full" bg="white" p={4}>
        <CardHeader>
          <Heading size="md">Dettagli</Heading>
        </CardHeader>
        <CardBody>
          {createResellerError ? (
            <ErrorText position="insideElement">{`${createResellerError}`}</ErrorText>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <SimpleGrid
                h="full"
                columns={{ base: 1, md: 2 }}
                spacingX={8}
                spacingY={2}
              >
                <FormInputText
                  label="Nome Azienda"
                  id="name"
                  error={errors.name}
                  register={register("name", {
                    required: "* campo obbligatorio",
                  })}
                />
                <FormInputText
                  label="Nome e Cognome Responsabile"
                  id="managerName"
                  error={errors.managerName}
                  register={register("managerName", {
                    required: "* campo obbligatorio",
                  })}
                />
                <FormInputText
                  label="Telefono Azienda"
                  id="phoneNumber"
                  error={errors.phoneNumber}
                  register={register("phoneNumber", {
                    required: "* campo obbligatorio",
                  })}
                />
                <FormInputText
                  label="Telefono Responsabile"
                  id="managerPhone"
                  error={errors.managerPhone}
                  register={register("managerPhone", {
                    required: "* campo obbligatorio",
                  })}
                />
                <FormInputText
                  label="Pec"
                  id="pec"
                  error={errors.pec}
                  register={register("pec", {
                    required: "* campo obbligatorio",
                  })}
                />
                <FormInputText
                  label="Email"
                  id="email"
                  error={errors.email}
                  register={register("email", {
                    required: "* campo obbligatorio",
                  })}
                />
              </SimpleGrid>
              <Heading marginTop={16} marginBottom={8} size="md">
                Dati Fatturazione
              </Heading>
              <SimpleGrid
                h="full"
                columns={{ base: 1, md: 2 }}
                spacingX={8}
                spacingY={2}
              >
                <FormInputText
                  label="Via"
                  id="street"
                  error={errors.street}
                  register={register("street", {
                    required: "* campo obbligatorio",
                  })}
                />
                <FormInputText
                  label="Città"
                  id="city"
                  error={errors.city}
                  register={register("city", {
                    required: "* campo obbligatorio",
                  })}
                />
                <FormInputText
                  label="CAP"
                  id="zipCode"
                  error={errors.zipCode}
                  register={register("zipCode", {
                    required: "* campo obbligatorio",
                  })}
                />
                <FormInputText
                  label="Provincia"
                  id="state"
                  error={errors.state}
                  register={register("state", {
                    required: "* campo obbligatorio",
                  })}
                />
                <FormInputText
                  label="Nazione"
                  isDisabled
                  id="country"
                  error={errors.country}
                  register={register("country", {
                    required: "* campo obbligatorio",
                  })}
                />
                <FormInputText
                  label="P.IVA / C.F."
                  id="vat"
                  error={errors.vat}
                  register={register("vat", {
                    required: "* campo obbligatorio",
                  })}
                />
                <FormInputText
                  label="Codice SDI"
                  id="sdi"
                  error={errors.sdi}
                  register={register("sdi", {
                    required: "* campo obbligatorio",
                  })}
                />
                {/* FIXME: convert to input Number */}
                <FormInputText
                  label="Provigione Corrisposta"
                  id="commission"
                  error={errors.commission}
                  register={register("commission", {
                    required: "* campo obbligatorio",
                  })}
                />
              </SimpleGrid>
              {renderEditButton()}
            </form>
          )}
        </CardBody>
      </Card>
    </Box>
  );
};

export { CreateResellerDetail };
