import {
  Shop,
  ShopChangeTypeData,
  ShopChangeTypeVariables,
  ShopEditData,
  ShopEditVariables,
  SHOP_CHANGE_TYPE,
  SHOP_EDIT,
} from "../../../../stores/queries/shop";
import { numberToEuro } from "../../../../libs/utils";
import { useMutation } from "@apollo/client";
import { LOG } from "../../../../config";

import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Text,
  Flex,
  VStack,
  StackDivider,
  FormLabel,
  Switch,
} from "@chakra-ui/react";
import { AddDateModal } from "./Modals/AddDateModal";
import { AddCreditsModal } from "./Modals/AddCreditsModal";
import { ErrorText } from "../../../../components/Ui";

const log = LOG.extend("EDIT_SHOP");

const getDate = (date: string | null) => {
  if (!date) return "-";
  return new Date(date).toLocaleDateString();
};

interface ServiceDetailprops {
  payments: Shop["servicePayments"];
  shopId: string;
  isEnabled: boolean;
  isMaintenance: boolean;
  type: Shop["type"];
}
const ServiceDetail = (props: ServiceDetailprops) => {
  const { payments, shopId, isEnabled, isMaintenance, type } = props;

  const [
    shopChangeType,
    { error: shopChangeTypeError, loading: shopChangeTypeLoading },
  ] = useMutation<ShopChangeTypeData, ShopChangeTypeVariables>(
    SHOP_CHANGE_TYPE
  );

  const [shopEdit, { error: shopEditError, loading: shopEditLoading }] =
    useMutation<ShopEditData, ShopEditVariables>(SHOP_EDIT);

  const changeEnabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    log.debug("CALL changeEnabled()");

    shopEdit({
      variables: {
        _id: shopId,
        shop: {
          isEnabled: event.target.checked,
        },
      },
    });

    log.info(`  - change shop.isEnabled to ${event.target.checked}`);
  };

  const changeMaintenance = (event: React.ChangeEvent<HTMLInputElement>) => {
    log.debug("CALL changeMaintenance()");

    shopEdit({
      variables: {
        _id: shopId,
        shop: {
          isMaintenance: event.target.checked,
        },
      },
    });

    log.info(`  - change shop.isMaintenance to ${event.target.checked}`);
  };

  const changeShopType = (event: React.ChangeEvent<HTMLInputElement>) => {
    log.debug("CALL changeMaintenance()");

    let newType: Shop["type"] | undefined = undefined;

    if (type === "light" && event.target.checked) {
      newType = "full";
    }
    if (type === "full" && !event.target.checked) {
      newType = "light";
    }

    if (!newType) {
      log.warn(
        `  - shop.type = ${type} & event.target.checked = ${event.target.checked} - do NOT update`
      );
      return;
    }

    shopChangeType({
      variables: {
        _id: shopId,
        type: newType,
      },
    });

    log.info(`  - change shop.type to ${newType}`);
  };

  return (
    <Box w="100%">
      <Card w="100%" h="full" bg="white" p={4}>
        <CardHeader>
          <Heading size="md">Stato Negozio</Heading>
        </CardHeader>
        <CardBody>
          {shopEditError ? (
            <ErrorText position="insideElement">
              {shopEditError?.message || "GraphQL Error"}
            </ErrorText>
          ) : (
            <VStack
              spacing={4}
              divider={<StackDivider borderColor="gray.200" />}
            >
              <Flex w={"full"} direction={"row"} gap={20} align="center" my={2}>
                <Box>
                  <Flex direction={"row"} align="center">
                    <FormLabel htmlFor="isEnabled" mb="0">
                      Abilitato
                    </FormLabel>
                    <Switch
                      id="isEnabled"
                      defaultChecked={isEnabled}
                      onChange={changeEnabled}
                    />
                  </Flex>
                </Box>
                <Box>
                  <Flex direction={"row"} align="center">
                    <FormLabel htmlFor="isMaintenance" mb="0">
                      In Manutenzione
                    </FormLabel>
                    <Switch
                      id="isMaintenance"
                      defaultChecked={isMaintenance}
                      onChange={changeMaintenance}
                    />
                  </Flex>
                </Box>
                <Box>
                  <Flex direction={"row"} align="center">
                    <FormLabel htmlFor="type" mb="0">
                      {type === "full" ? "Full" : "Light"}
                    </FormLabel>
                    <Switch
                      id="type"
                      defaultChecked={type === "full"}
                      onChange={changeShopType}
                    />
                  </Flex>
                </Box>
              </Flex>
              <Flex
                w="full"
                direction={"row"}
                justify="space-between"
                align="center"
              >
                <Text>
                  Servizio attivo:
                  <Text as={"span"} pl={4}>
                    Da:
                  </Text>
                  <Text as={"span"} fontWeight={"semibold"} px={4}>
                    {`${getDate(payments.startDate)}`}
                  </Text>
                  A
                  <Text as={"span"} fontWeight={"semibold"} px={4}>
                    {`${getDate(payments.endDate)}`}
                  </Text>
                </Text>
                <AddDateModal
                  endDateString={payments.endDate}
                  shopId={shopId}
                />
              </Flex>
              <Flex
                w="full"
                direction={"row"}
                justify="space-between"
                align="center"
              >
                <Text>
                  Credito:
                  <Text as={"span"} fontWeight={"semibold"} px={4}>
                    {`${numberToEuro(payments.credits)}`}
                  </Text>
                </Text>
                <AddCreditsModal credits={payments.credits} shopId={shopId} />
              </Flex>
            </VStack>
          )}
        </CardBody>
      </Card>
    </Box>
  );
};

export { ServiceDetail };
