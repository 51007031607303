import { ENV, LOG } from "../../config";
import { envStorage } from "../../libs/storages";

const log = LOG.extend("DB");
interface Auth {
  user?: string | null;
  accessToken?: string | null;
  refreshToken?: string | null;
  shop?: string | null;
}

let ACTUAL_AUTH_INIT: Auth = {
  user: null,
  refreshToken: null,
  accessToken: null,
  shop: null,
};

let ACTUAL_AUTH: Auth = ACTUAL_AUTH_INIT;

const DB_AUTH = {
  get: async () => {
    let authSaved = null;
    try {
      authSaved = await envStorage.getObject(ENV.db.authKey);
    } catch ({ message }) {
      log.error(`Get DB_AUTH ERROR: ${message}`);
    }
    if (authSaved) {
      ACTUAL_AUTH = authSaved;
    } else {
      log.warn("No DB_AUTH found");
      ACTUAL_AUTH = { ...ACTUAL_AUTH_INIT };
    }
    return ACTUAL_AUTH;
  },
  set: async (params: Auth) => {
    ACTUAL_AUTH = {
      ...ACTUAL_AUTH,
      ...params,
    };
    try {
      await envStorage.setObject(ENV.db.authKey, ACTUAL_AUTH);
    } catch ({ message }) {
      log.error(`Set DB_AUTH ERROR: ${message}`);
    }
    return ACTUAL_AUTH;
  },
  clear: async () => {
    log.debug("CLEAR AUTHPARAM");
    ACTUAL_AUTH = { ...ACTUAL_AUTH_INIT };
    try {
      await envStorage.remove(ENV.db.authKey);
    } catch ({ message }) {
      log.error(`Remove DB_AUTH ERROR: ${message}`);
    }
    return ACTUAL_AUTH;
  },
};

export { DB_AUTH, ACTUAL_AUTH };
export type { Auth };
