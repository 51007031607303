import { useMutation } from "@apollo/client";
import {
  Box,
  Card,
  CardHeader,
  CardBody,
  Heading,
  SimpleGrid,
  Button,
  Flex,
} from "@chakra-ui/react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ErrorText, FormInputText } from "../../../components/Ui";
import { LOG } from "../../../config";
import {
  DuplicateShopData,
  DuplicateShopVariables,
  DUPLICATE_SHOP,
} from "../../../stores/queries/shop";

const log = LOG.extend("DUPLICATE_SHOP");

interface Inputs {
  name: string;
  street: string;
  city: string;
  zipCode: string;
  state: string;
  phoneNumber: string;
  vat: string;
  email: string;
  ownerName: string;
}

interface DuplicateShopDetailProps {
  shopId: string;
}
const DuplicateShopDetail = (props: DuplicateShopDetailProps) => {
  const { shopId } = props;
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>({
    defaultValues: {
      name: "",
      street: "",
      city: "",
      zipCode: "",
      state: "",
      phoneNumber: "",
      vat: "",
      email: "",
      ownerName: "",
    },
  });

  const [
    createShop,
    { error: duplicateShopError, loading: duplicateShopLoading },
  ] = useMutation<DuplicateShopData, DuplicateShopVariables>(DUPLICATE_SHOP);

  const onSubmit: SubmitHandler<Inputs> = async (inputs) => {
    const { data } = await createShop({
      variables: {
        _id: shopId,
        owner: {
          email: inputs.email,
          basicInfo: {
            name: inputs.ownerName,
          },
        },
        shop: {
          name: inputs.name,
          vat: inputs.vat,
          addresses: {
            commercial: [],
            legal: {
              city: inputs.city,
              state: inputs.state,
              zipCode: inputs.zipCode,
              phoneNumber: inputs.phoneNumber,
              street: inputs.street,
              denomination: inputs.name,
              country: "IT",
            },
          },
        },
      },
    });

    if (!data?.RootShopDuplicate) {
      log.error(" - createShop mutation - something went wrong");
      return;
    }

    log.info(
      `Created Shop: _id: ${data.RootShopDuplicate._id} - code: ${data.RootShopDuplicate.code}`
    );

    reset();
    navigate(-1);
  };

  const onCancel = () => {
    reset();
    navigate(-1);
  };

  const renderEditButton = () => {
    return (
      <Flex direction={"row"} justify="end" mt={8}>
        <Button
          onClick={onCancel}
          colorScheme={"red"}
          mr={3}
          isLoading={duplicateShopLoading || isSubmitting}
          variant={"outline"}
        >
          Annulla
        </Button>
        <Button
          colorScheme="teal"
          type="submit"
          isLoading={duplicateShopLoading || isSubmitting}
          variant={"outline"}
        >
          Duplica
        </Button>
      </Flex>
    );
  };

  return (
    <Box w="100%">
      <Card w="100%" h="full" bg="white" p={4}>
        <CardHeader>
          <Heading size="md">Dettagli Nuovo Negozio</Heading>
        </CardHeader>
        <CardBody>
          {duplicateShopError ? (
            <ErrorText position="insideElement">{`${duplicateShopError}`}</ErrorText>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <SimpleGrid
                h="full"
                columns={{ base: 1, md: 2 }}
                spacingX={8}
                spacingY={2}
              >
                <FormInputText
                  label="Nome"
                  id="name"
                  error={errors.name}
                  register={register("name", {
                    required: "* campo obbligatorio",
                  })}
                />
                <FormInputText
                  label="Via"
                  id="street"
                  error={errors.street}
                  register={register("street", {
                    required: "* campo obbligatorio",
                  })}
                />
                <FormInputText
                  label="Città"
                  id="city"
                  error={errors.city}
                  register={register("city", {
                    required: "* campo obbligatorio",
                  })}
                />
                <FormInputText
                  label="CAP"
                  id="zipCode"
                  error={errors.zipCode}
                  register={register("zipCode", {
                    required: "* campo obbligatorio",
                  })}
                />
                <FormInputText
                  label="Provincia"
                  id="state"
                  error={errors.state}
                  register={register("state", {
                    required: "* campo obbligatorio",
                  })}
                />
                <FormInputText
                  label="Telefono"
                  id="phoneNumber"
                  error={errors.phoneNumber}
                  register={register("phoneNumber", {
                    required: "* campo obbligatorio",
                  })}
                />
                <FormInputText
                  label="P.IVA"
                  id="vat"
                  error={errors.vat}
                  register={register("vat", {
                    required: "* campo obbligatorio",
                  })}
                />
                <FormInputText
                  label="Email proprietario"
                  id="email"
                  error={errors.email}
                  register={register("email", {
                    required: "* campo obbligatorio",
                  })}
                />
                <FormInputText
                  label="Nome proprietario"
                  id="ownerName"
                  error={errors.ownerName}
                  register={register("ownerName", {
                    required: "* campo obbligatorio",
                  })}
                />
              </SimpleGrid>
              {renderEditButton()}
            </form>
          )}
        </CardBody>
      </Card>
    </Box>
  );
};

export { DuplicateShopDetail };
