import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";

// THEME
import { theme } from "./styles/theme";
// CSS
import "./assets/fonts/easypixy-icons.css";
import "./assets/fonts/fonts.css";

// LIBS
import { DB_AUTH } from "./stores/db/auth";
import { LOG } from "./config";
import { createApolloClient } from "./libs/apollo/createClient";
import { userLogged } from "./stores/db/appstate";

import { LoadingPage } from "./pages/LoadingPage";
import { Navigation } from "./components/Navigation";

const Root = () => {
  const [client, setClient] = useState<any>(undefined);

  useEffect(() => {
    const initApp = async () => {
      const authSaved = await DB_AUTH.get();
      const apolloClient = await createApolloClient();
      if (authSaved && authSaved.user) {
        LOG.info("User previous logged");
        userLogged(authSaved.user);
      }
      if (apolloClient) {
        setClient(apolloClient);
      } else {
        LOG.error("Unable to initialize client");
      }
    };

    initApp();
  }, []);

  if (!client) {
    return (
      <ChakraProvider theme={theme}>
        <LoadingPage />
      </ChakraProvider>
    );
  }

  return (
    <ApolloProvider client={client}>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <Navigation />
        </BrowserRouter>
      </ChakraProvider>
    </ApolloProvider>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <Root />
  </React.StrictMode>
);
