import { ArrowBackIcon } from "@chakra-ui/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  Job,
  JobPaymentStatus,
  JOBS,
  JobsData,
  JobStatus,
  JobsVariable,
  PAYMENT_STATUS,
  STATUS,
} from "../../../stores/queries/job";
import {
  Box,
  Heading,
  Button,
  Center,
  Spinner,
  Card,
  CardBody,
  Text,
  HStack,
  IconButton,
  Flex,
} from "@chakra-ui/react";
import { DownloadIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import { ErrorText, Table } from "../../../components/Ui";
import { LOG } from "../../../config";
import { Breadcumbs } from "../../../components/Breadcrumbs";
import { useState } from "react";

const log = LOG.extend("ORDER_LIST");

const getStatusName = (status: JobStatus) => {
  let statusObj = STATUS.find((s) => s.value === status);
  let statusLabel = statusObj ? statusObj.label : "Sconosciuto";

  return statusLabel;
};

const getPaymentStatusName = (status: JobPaymentStatus) => {
  let statusObj = PAYMENT_STATUS.find((s) => s.value === status);
  let statusLabel = statusObj ? statusObj.label : "Sconosciuto";

  return statusLabel;
};

const OrderList = () => {
  const params: any = useParams();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  const columns = [
    {
      id: "creationDate",
      name: "Ricevuto il",
      selector: (row: Job) => row.creationDate,
      sortable: true,
      minWidth: "15%",
      cell: (row: Job) => {
        if (!row.creationDate) return "-";
        let date = new Date(row.creationDate);
        let dateString = `${date.toLocaleDateString()}`;
        let timeString = `${date.toLocaleTimeString()}`;
        return (
          <div style={{ pointerEvents: "none" }}>
            <Text>{dateString}</Text>
            <Text>{timeString}</Text>
          </div>
        );
      },
    },
    {
      id: "user",
      name: "Cliente",
      selector: (row: Job) => {
        let name = row.user.username;
        return name || "-";
      },
      sortable: true,
      minWidth: "15%",
      cell: (row: Job) => {
        let name = row.user.username;
        let email = row.user.email;
        return (
          <div style={{ pointerEvents: "none" }}>
            <Text fontWeight={"semibold"}>{name || "-"}</Text>
            <Text>{email || "-"}</Text>
          </div>
        );
      },
    },
    {
      id: "price",
      name: "Prezzo",
      selector: (row: Job) => row.finalPrice,
      sortable: true,
      center: true,
      hide: "md",
      // minWidth: "15%",
      cell: (row: Job) => {
        return (
          <div style={{ pointerEvents: "none" }}>
            <Text>{`${row.finalPrice || "-"} €`}</Text>
          </div>
        );
      },
    },
    {
      id: "status",
      name: "Stato Ordine",
      selector: (row: Job) => row.status,
      // minWidth: "15%",
      sortable: true,
      center: true,
      cell: (row: Job) => {
        let statusLabel = getStatusName(row.status);
        return (
          <div style={{ pointerEvents: "none" }}>
            <Text textAlign={"center"}>{statusLabel}</Text>
          </div>
        );
      },
    },
    {
      id: "payment",
      name: <div style={{ textAlign: "center" }}>Stato pagamento utente</div>,
      selector: (row: Job) => row.payment.status,
      sortable: true,
      center: true,
      // minWidth: "15%",
      cell: (row: Job) => {
        let statusLabel = getPaymentStatusName(row.payment.status);
        return (
          <div style={{ pointerEvents: "none" }}>
            <Text>{statusLabel}</Text>
          </div>
        );
      },
    },
    {
      id: "credits",
      name: "Crediti",
      selector: (row: Job) => row.jobPayedFromShop,
      sortable: true,
      center: true,
      // minWidth: "15%",
      cell: (row: Job) => {
        let label = row.jobPayedFromShop ? "Già versati" : "Non versati";
        let fee = row.shop?.servicePayments?.actualPercentualFee
          ? Math.round(
              (row.shop.servicePayments.actualPercentualFee / 100) *
                row.finalPrice *
                100
            ) / 100
          : null;
        return (
          <div style={{ pointerEvents: "none" }}>
            <Text>{fee || "-"}</Text>
            <Text>{label}</Text>
          </div>
        );
      },
    },
    {
      id: "actions",
      name: "Azioni",
      center: true,
      // minWidth: "15%",
      cell: (row: Job) => {
        return (
          <HStack>
            <IconButton
              size={"xs"}
              variant={"outline"}
              aria-label="Downalod"
              icon={<DownloadIcon />}
              onClick={() => {
                log.debug("click Download Order");
              }}
            />
            <IconButton
              size={"xs"}
              variant={"outline"}
              aria-label="Detail"
              icon={<InfoOutlineIcon />}
              onClick={() => {
                navigate(`./${row._id}`);
              }}
            />
          </HStack>
        );
      },
    },
  ];

  const JOBS_FOR_PAGES = 30;

  const { loading, error, data } = useQuery<JobsData, JobsVariable>(JOBS, {
    variables: {
      shopId: params.id || "",
      offset: (page - 1) * JOBS_FOR_PAGES,
      limit: JOBS_FOR_PAGES,
      orderStatuses: [
        "initializing",
        "readyForPayment",
        "readyForProcessing",
        "processing",
        "canceledByUser",
        "canceledByAdmin",
        "confirmed",
        "errorInProcessing",
        "inElaboration",
        "elaborated",
        "ready",
      ],
    },
    fetchPolicy: "cache-and-network",
    pollInterval: 5000,
  });

  if (error) {
    log.error(error);
    return (
      <ErrorText position="fullPage">
        {error?.message || "GraphQL Error"}
      </ErrorText>
    );
  }

  const renderOrderList = () => {
    if (!data?.AdminJobs) {
      return (
        <Center>
          <Spinner
            speed="0.6s"
            emptyColor="purple.200"
            color="purple.400"
            size="lg"
          />
        </Center>
      );
    }
    return (
      <Box w="100%">
        <Card w="100%" h="full" bg="white" p={4}>
          <CardBody>
            <Flex
              direction={"column"}
              position={"relative"}
              w="full"
              maxH={"calc(100vh - 350px)"}
              overflow={"auto"}
            >
              <Table
                columns={columns}
                data={data?.AdminJobs.jobs || []}
                loading={loading}
                rowNotClickable
                pagination
                paginationServer
                paginationPerPage={JOBS_FOR_PAGES}
                paginationTotalRows={data?.AdminJobs.count}
                paginationDefaultPage={page}
                onChangePage={(page) => {
                  log.debug(`change page to ${page}`);
                  setPage(page);
                }}
              />
            </Flex>
          </CardBody>
        </Card>
      </Box>
    );
  };
  return (
    <Box pos={"absolute"} mt={"6rem"} p={8} w="100%" h="calc(100vh - 6rem)">
      <Box mb={8} px={4}>
        <Breadcumbs />
        <Heading size={"xl"} mb={2}>
          {`Lista Ordini - ${data?.AdminJobs?.jobs?.[0]?.shop?.name || ""}`}
        </Heading>
        <Button
          leftIcon={<ArrowBackIcon />}
          variant="link"
          colorScheme={"gray"}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      </Box>
      {renderOrderList()}
    </Box>
  );
};

export { OrderList };
