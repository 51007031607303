import { useNavigate, useParams } from "react-router-dom";

import { Box, Heading, Button, Flex } from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";

import { LOG } from "../../../config";
import { Breadcumbs } from "../../../components/Breadcrumbs";
import { DuplicateShopDetail } from "./DuplicateShopDetail";
import { SHOP_NAME } from "../../../stores/queries/shop";
import { useQuery } from "@apollo/client";

const log = LOG.extend("DUPLICATE_SHOP");

const DuplicateShop = () => {
  const params: any = useParams();
  const navigate = useNavigate();

  const { data, error, loading } = useQuery<
    { Shop: { name: string } },
    { _id: string }
  >(SHOP_NAME, {
    variables: { _id: params.id },
    fetchPolicy: "cache-and-network",
  });

  return (
    <Box pos={"absolute"} mt={"6rem"} p={8} w="100%" h="calc(100vh - 6rem)">
      <Box mb={8} px={4}>
        <Flex direction="row" align={"center"} justify={"space-between"}>
          <Box>
            <Breadcumbs />
            <Heading size={"xl"} mb={2}>
              {`Duplica Negozio - ${data?.Shop.name || "-"}`}
            </Heading>
            <Button
              leftIcon={<ArrowBackIcon />}
              variant="link"
              colorScheme={"gray"}
              onClick={() => navigate(-1)}
            >
              Home
            </Button>
          </Box>
        </Flex>
      </Box>
      <DuplicateShopDetail shopId={params.id} />
      <Box py={4}></Box>
    </Box>
  );
};

export { DuplicateShop };
