import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { LOG, __DEV__ } from "../../../config";

import {
  Box,
  Heading,
  Button,
  Card,
  CardBody,
  Flex,
  HStack,
  IconButton,
  InputGroup,
  Input,
  InputLeftElement,
} from "@chakra-ui/react";
import {
  AddIcon,
  ArrowBackIcon,
  DeleteIcon,
  DownloadIcon,
  SearchIcon,
} from "@chakra-ui/icons";
import { ErrorText, Table } from "../../../components/Ui";
import { Media, TableColumn } from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { Breadcumbs } from "../../../components/Breadcrumbs";
import { RESELLERS, Reseller } from "../../../stores/queries/reseller";

const log = LOG.extend("RESELLER_LIST");

const ResellerList = () => {
  const navigate = useNavigate();
  const { data, error, loading } = useQuery<{ Resellers: Reseller[] }>(
    RESELLERS,
    {
      fetchPolicy: "cache-and-network",
    }
  );
  const [filteredResellers, setFilteredResellers] = useState<Reseller[]>([]);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    if (data?.Resellers) {
      setFilteredResellers(
        data.Resellers.filter((reseller) => {
          if (search === "") {
            return true;
          }
          return reseller.name.toLowerCase().includes(search.toLowerCase());
        })
      );
    }
  }, [data, search]);

  const columns: TableColumn<Reseller>[] = [
    {
      name: "nome",
      selector: (row: Reseller) => row.name,
      sortable: true,
    },
    {
      name: "Nazione",
      selector: (row: Reseller) => row.address.country,
      cell: (row: Reseller) => {
        return row?.address?.country === "IT" ? "Italia" : "Altro";
      },
    },
    {
      name: "Proprietario",
      selector: (row: Reseller) => row.manager.name,
      hide: Media.MD,
      sortable: true,
    },
    {
      name: "Telefono",
      selector: (row: Reseller) => row.phone,
      hide: Media.MD,
      sortable: true,
    },
    {
      name: "E-mail",
      selector: (row: Reseller) => row.email,
      hide: Media.MD,
      sortable: true,
    },
    {
      name: "Provvigione",
      selector: (row: Reseller) => row.commission || "-",
      sortable: true,
    },
    {
      id: "actions",
      name: "Azioni",
      hide: Media.MD,
      center: true,
      ignoreRowClick: true,
      cell: (row: Reseller) => {
        return (
          <IconButton
            size={"xs"}
            isDisabled
            variant={"outline"}
            aria-label="Delete"
            icon={<DeleteIcon />}
            onClick={() => {
              //TODO: delete reseller
              log.debug("delete reseller");
            }}
          />
        );
      },
    },
  ];

  if (error) {
    log.error(error);
    return (
      <ErrorText position="fullPage">
        {error?.message || "GraphQL Error"}
      </ErrorText>
    );
  }

  return (
    <Box pos={"absolute"} mt={"6rem"} p={8} w="100%" h="calc(100vh - 6rem)">
      <Box mb={8} px={4}>
        <Flex direction="row" align={"center"} justify={"space-between"}>
          <Box>
            <Breadcumbs />
            <Heading size={"xl"} mb={2}>
              Lista Rivenditori
            </Heading>
            <Button
              leftIcon={<ArrowBackIcon />}
              variant="link"
              colorScheme={"gray"}
              onClick={() => navigate(-1)}
            >
              Home
            </Button>
          </Box>
          <HStack gap={2}>
            <Button
              variant="outline"
              onClick={() => navigate("/resellers/new")}
              rightIcon={<AddIcon />}
            >
              Nuovo Rivenditore
            </Button>
            <Button
              variant="outline"
              isDisabled
              //TODO: download report
              // onClick={() => navigate("/processes")}
              rightIcon={<DownloadIcon />}
            >
              Scarica Report
            </Button>
            <Box>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.400" />
                </InputLeftElement>
                <Input
                  borderColor={"primary.400"}
                  _hover={{ backgroundColor: "primary.50" }}
                  variant={"outline"}
                  placeholder="Cerca per Nome..."
                  onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
            </Box>
          </HStack>
        </Flex>
      </Box>
      <Box w="100%">
        <Card w="100%" h="full" bg="white" p={4}>
          <CardBody>
            <Table
              columns={columns}
              data={filteredResellers}
              loading={loading}
              onRowClick={(row: Reseller, event) => {
                //TODO: navigate to reseller detail
                // navigate("/shop/" + row.name);
              }}
            />
          </CardBody>
        </Card>
      </Box>
    </Box>
  );
};

export { ResellerList };
