import {
  Box,
  Flex,
  Image,
  Button,
  useColorMode,
  IconButton,
  VStack,
  Heading,
  Text,
  StackDivider,
  SimpleGrid,
  Hide,
} from "@chakra-ui/react";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import Logo from "../../assets/imgs/easypixy-logo.png";
import { ENV } from "../../config";
import { logout } from "../../libs/apollo/auth";

const Navbar = () => {
  // const { colorMode, toggleColorMode } = useColorMode();
  return (
    <Box
      position="absolute"
      top={0}
      right={0}
      w="100%"
      h={"6rem"}
      bg="whiteAlpha.800"
      zIndex={1}
    >
      <SimpleGrid h="full" columns={{ base: 2, md: 3 }}>
        <Hide below="md">
          <Flex h="full" w="full" justify="start" align="center" px={12}>
            <Image h="full" w={"180px"} objectFit={"contain"} src={Logo} />
          </Flex>
        </Hide>

        <VStack
          h={"full"}
          px={{ base: 12, md: 0 }}
          justify={"center"}
          align={{ base: "start", md: "center" }}
          divider={<StackDivider borderColor="gray.200" />}
          spacing={1}
        >
          <Heading
            size={{ base: "md", md: "lg" }}
            textAlign={{ base: "left", md: "center" }}
          >
            Admin Dashboard
          </Heading>
          <Text size={{ base: "xs", md: "md" }}>
            {`ver. ${ENV.deviceInfo.app.version}${
              ENV.mode === "test" ? " - test" : ""
            }`}
          </Text>
        </VStack>

        <Flex h="full" w="full" justify="end" align="center" px={12}>
          <Button
            size={"sm"}
            variant={"outline"}
            onClick={() => {
              logout();
            }}
          >
            Log Out
          </Button>
        </Flex>
      </SimpleGrid>
    </Box>
  );
};

export { Navbar };

// import React from "react";
// import { Link, useNavigate } from "react-router-dom";
// import useBreadcrumbs from "use-react-router-breadcrumbs";

// import Logo from "../../../assets/imgs/easypixy-logo.png";

// const DynamicShopBreadcrumb = ({ match }: any) => (
//   <span className="text-gray-500">{`Shop: ${match.params.id}`}</span>
// );

// const routes = [
//   { path: "/", breadcrumb: null },
//   { path: "/admin", breadcrumb: null },
//   { path: "/admin/:id", breadcrumb: DynamicShopBreadcrumb },
// ];

// const Navbar = () => {
//   const breadcrumbs = useBreadcrumbs(routes);
//   const navigate = useNavigate();

//   return (
//     <div className="absolute navbar glass z-10">
//       <div className="navbar-start">
//         <button
//           className="btn btn-ghost"
//           onClick={() => {
//             navigate("/admin");
//             console.log("home");
//           }}
//         >
//           <img alt="EasyPixy" src={Logo} className="w-36 h-auto border-none" />
//         </button>
//         <div className="text-sm breadcrumbs flex flex-1">
//           <ul>
//             <li></li>
//             {breadcrumbs.map(({ match, breadcrumb }) => (
//               <li key={match.pathname}>
//                 <button className="btn btn-ghost btn-sm">
//                   <Link to={match.pathname}>{breadcrumb}</Link>
//                 </button>
//               </li>
//             ))}
//           </ul>
//         </div>
//       </div>

//       <div className="navbar-end">
//         <p></p>
//         <button
//           className="btn btn-ghost"
//           onClick={() => {
//             console.log("logOut");
//           }}
//         >
//           Log out
//         </button>
//       </div>
//     </div>
//   );
// };

// export { Navbar };
