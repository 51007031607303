import { useMutation } from "@apollo/client";
import {
  Box,
  Card,
  CardHeader,
  CardBody,
  Heading,
  SimpleGrid,
  Button,
  Flex,
  FormLabel,
  Switch,
} from "@chakra-ui/react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ErrorText, FormInputText } from "../../../components/Ui";
import { LOG } from "../../../config";
import {
  CreateShopData,
  CreateShopVariables,
  CREATE_SHOP,
  Shop,
} from "../../../stores/queries/shop";

const log = LOG.extend("CREATE_SHOP");

interface Inputs {
  type: Shop["type"];
  name: string;
  street: string;
  city: string;
  zipCode: string;
  state: string;
  phoneNumber: string;
  vat: string;
  email: string;
  ownerName: string;
}

const CreateShopDetail = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>({
    defaultValues: {
      type: "full",
      name: "",
      street: "",
      city: "",
      zipCode: "",
      state: "",
      phoneNumber: "",
      vat: "",
      email: "",
      ownerName: "",
    },
  });

  const [createShop, { error: createShopError, loading: createShopLoading }] =
    useMutation<CreateShopData, CreateShopVariables>(CREATE_SHOP);

  const onSubmit: SubmitHandler<Inputs> = async (inputs) => {
    const { data } = await createShop({
      variables: {
        owner: {
          email: inputs.email,
          basicInfo: {
            name: inputs.ownerName,
          },
        },
        shop: {
          name: inputs.name,
          vat: inputs.vat,
          type: inputs.type,
          addresses: {
            commercial: [],
            legal: {
              city: inputs.city,
              state: inputs.state,
              zipCode: inputs.zipCode,
              phoneNumber: inputs.phoneNumber,
              street: inputs.street,
              denomination: inputs.name,
              country: "IT",
            },
          },
        },
      },
    });

    if (!data?.createShop) {
      log.error(" - createShop mutation - something went wrong");
      return;
    }

    log.info(
      `Created Shop: _id: ${data.createShop._id} - code: ${data.createShop.code}`
    );

    reset();
    navigate("/shop/" + data.createShop._id);
  };

  const onCancel = () => {
    reset();
    navigate(-1);
  };

  const toogleType = (event: React.ChangeEvent<HTMLInputElement>) => {
    log.debug("CALL toogleType()");

    let newType: Shop["type"] | undefined = undefined;
    let type = watch("type");

    if (type === "light" && event.target.checked) {
      newType = "full";
    }
    if (type === "full" && !event.target.checked) {
      newType = "light";
    }

    if (!newType) {
      log.warn(
        `  - shop.type = ${type} & event.target.checked = ${event.target.checked} - do NOT update`
      );
      return;
    }

    setValue("type", newType);
  };

  const renderEditButton = () => {
    return (
      <Flex direction={"row"} justify="end" mt={8}>
        <Button
          onClick={onCancel}
          colorScheme={"red"}
          mr={3}
          isLoading={createShopLoading || isSubmitting}
          variant={"outline"}
        >
          Annulla
        </Button>
        <Button
          colorScheme="teal"
          type="submit"
          isLoading={createShopLoading || isSubmitting}
          variant={"outline"}
        >
          Crea
        </Button>
      </Flex>
    );
  };

  return (
    <Box w="100%">
      <Card w="100%" h="full" bg="white" p={4}>
        <CardHeader>
          <Heading size="md">Dettagli Negozio</Heading>
        </CardHeader>
        <CardBody>
          {createShopError ? (
            <ErrorText position="insideElement">{`${createShopError}`}</ErrorText>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box>
                <Flex direction={"column"} align={"start"} gap={4} mb={10}>
                  <FormLabel fontWeight={"bold"} htmlFor="type" mb="0">
                    {watch("type") === "full"
                      ? "Negozio Full"
                      : "Negozio Light"}
                  </FormLabel>
                  <Switch
                    id="isEnabled"
                    defaultChecked={watch("type") === "full"}
                    onChange={toogleType}
                  />
                </Flex>
              </Box>
              <SimpleGrid
                h="full"
                columns={{ base: 1, md: 2 }}
                spacingX={8}
                spacingY={2}
              >
                <FormInputText
                  label="Nome"
                  id="name"
                  error={errors.name}
                  register={register("name", {
                    required: "* campo obbligatorio",
                  })}
                />
                <FormInputText
                  label="Via"
                  id="street"
                  error={errors.street}
                  register={register("street", {
                    required: "* campo obbligatorio",
                  })}
                />
                <FormInputText
                  label="Città"
                  id="city"
                  error={errors.city}
                  register={register("city", {
                    required: "* campo obbligatorio",
                  })}
                />
                <FormInputText
                  label="CAP"
                  id="zipCode"
                  error={errors.zipCode}
                  register={register("zipCode", {
                    required: "* campo obbligatorio",
                  })}
                />
                <FormInputText
                  label="Provincia"
                  id="state"
                  error={errors.state}
                  register={register("state", {
                    required: "* campo obbligatorio",
                  })}
                />
                <FormInputText
                  label="Telefono"
                  id="phoneNumber"
                  error={errors.phoneNumber}
                  register={register("phoneNumber", {
                    required: "* campo obbligatorio",
                  })}
                />
                <FormInputText
                  label="P.IVA"
                  id="vat"
                  error={errors.vat}
                  register={register("vat", {
                    required: "* campo obbligatorio",
                  })}
                />
                <FormInputText
                  label="Email proprietario"
                  id="email"
                  error={errors.email}
                  register={register("email", {
                    required: "* campo obbligatorio",
                  })}
                />
                <FormInputText
                  label="Nome proprietario"
                  id="ownerName"
                  error={errors.ownerName}
                  register={register("ownerName", {
                    required: "* campo obbligatorio",
                  })}
                />
              </SimpleGrid>
              {renderEditButton()}
            </form>
          )}
        </CardBody>
      </Card>
    </Box>
  );
};

export { CreateShopDetail };
