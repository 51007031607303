// @ts-ignore
import omitDeep from 'omit-deep-lodash';
import cloneDeep from 'lodash/cloneDeep'
import { LOG } from '../../config';

export const cleanApolloData = (data: Object) => {
  if(!data) return null;
  try {
    return omitDeep(cloneDeep(data), '__typename') as Object; 
  } catch (error) {
    LOG.error(error);
    return null;
  }
}