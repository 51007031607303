import { FormControl, FormLabel } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { RESELLERS, Reseller } from "../../../stores/queries/reseller";
import { useQuery } from "@apollo/client";
import { ErrorText } from "../../../components/Ui";
import { LOG } from "../../../config";

const log = LOG.extend("EDIT_SHOP");

interface SelectOption {
  value: string;
  label: string;
}

const getSelectOptions = (resellers: Reseller[]): SelectOption[] => {
  return resellers.map((reseller) => ({
    value: reseller._id,
    label: reseller.name,
  }));
};

const getActualValue = (resellers: Reseller[], resellerId?: string) => {
  const reseller = resellers.find((reseller) => reseller._id === resellerId);
  if (reseller) {
    return {
      value: reseller._id,
      label: reseller.name,
    };
  }
  return undefined;
};

interface SelectResellerProps {
  label?: string;
  resellerId?: string;
  placeholder?: string;
  isDisabled?: boolean;
  size?: "sm" | "md" | "lg";
  textAlign?: "left" | "center" | "right";
  onChange: (value?: string) => void;
}
const SelectReseller = (props: SelectResellerProps) => {
  const {
    label,
    placeholder,
    isDisabled,
    size,
    textAlign,
    onChange,
    resellerId,
  } = props;

  const { data, error, loading } = useQuery<{ Resellers: Reseller[] }>(
    RESELLERS,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  if (error) {
    log.error(error);
    return (
      <ErrorText position="insideElement">
        {error?.message || "GraphQL Error"}
      </ErrorText>
    );
  }

  return (
    <FormControl>
      <FormLabel textAlign={textAlign}>{label || null}</FormLabel>
      <Select
        value={getActualValue(data?.Resellers || [], resellerId)}
        onChange={(newValue) => {
          onChange(newValue?.value);
        }}
        isLoading={loading}
        selectedOptionColorScheme="primary"
        noOptionsMessage={() => "Nessun rivenditore trovato"}
        isClearable={true}
        placeholder={placeholder}
        isDisabled={isDisabled}
        size={size}
        options={getSelectOptions(data?.Resellers || [])}
        // DEV
        // options={[
        //   { value: "1", label: "Reseller 1" },
        //   { value: "2", label: "Reseller 2" },
        //   { value: "3", label: "Reseller 3" },
        // ]}
        // DEV
      />
    </FormControl>
  );
};
export { SelectReseller };
