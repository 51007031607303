import { useMutation } from "@apollo/client";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Button,
  Text,
} from "@chakra-ui/react";
import { LOG } from "../../../../../config";
import {
  ShopUpdatePeriodData,
  ShopUpdatePeriodVariables,
  SHOP_UPDATE_PERIOD,
} from "../../../../../stores/queries/shop";

const log = LOG.extend("EDIT_SHOP");

interface AddDateModalProps {
  endDateString: string | null;
  shopId: string;
}
const AddDateModal = (props: AddDateModalProps) => {
  const { endDateString, shopId } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [shopUpdatePeriod, { error, loading }] = useMutation<
    ShopUpdatePeriodData,
    ShopUpdatePeriodVariables
  >(SHOP_UPDATE_PERIOD);

  const getNewEndDate = () => {
    let originalDate = new Date();
    if (endDateString) {
      originalDate =
        new Date(endDateString) > new Date()
          ? new Date(endDateString)
          : new Date();
    }
    return new Date(
      new Date(originalDate).setFullYear(originalDate.getFullYear() + 1)
    );
  };

  const getDate = (date: string | null) => {
    if (!date) return "-";
    return new Date(date).toLocaleDateString();
  };

  const onCancel = () => {
    onClose();
  };
  const onConfirm = async () => {
    log.debug("CALL onConfirm()");

    const newEndDate = getNewEndDate();
    if (!newEndDate) {
      log.error(` - can not valuate new end date from ${endDateString}`);
      return;
    }

    const { data } = await shopUpdatePeriod({
      variables: {
        _id: shopId,
        endDate: newEndDate.toISOString(),
      },
    });

    if (!data?.RootShopServicePaymentUpdatePeriod) {
      log.error(" - shopUpdatePeriod mutation - something went wrong");
      return;
    }

    log.info(
      ` - update shop period endDate from ${endDateString} to ${getNewEndDate()?.toISOString()} } `
    );

    onClose();
  };

  return (
    <>
      <Button variant={"outline"} onClick={onOpen}>
        Rinnova
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent alignItems={"center"}>
          <ModalHeader>Rinnova Abbonamento</ModalHeader>
          <ModalBody>
            <Text textAlign={"center"}>
              {`L'abbonamento attuale scale: ${getDate(endDateString)}`}
            </Text>
            <Text textAlign={"center"} mt={2}>
              Vuoi rinnovare l'abbonamento fino a:
            </Text>
            <Text textAlign={"center"} mt={2} fontWeight="semibold">
              {`${getNewEndDate()?.toLocaleDateString()}`}
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button
              variant={"outline"}
              colorScheme="red"
              mr={3}
              onClick={onCancel}
              isLoading={loading}
            >
              Annulla
            </Button>
            <Button
              variant={"outline"}
              colorScheme="teal"
              onClick={onConfirm}
              isLoading={loading}
            >
              Conferma
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export { AddDateModal };
