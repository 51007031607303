import { useMutation } from "@apollo/client";
import {
  Box,
  Card,
  CardHeader,
  CardBody,
  Heading,
  SimpleGrid,
  Button,
  Flex,
} from "@chakra-ui/react";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ErrorText, FormInputText } from "../../../components/Ui";
import { FormInputNumber } from "../../../components/Ui/FormInputNumber";
import { LOG } from "../../../config";
import { cleanApolloData } from "../../../libs/utils";
import {
  Shop,
  ShopEditData,
  ShopEditVariables,
  SHOP_EDIT,
} from "../../../stores/queries/shop";
import { SelectReseller } from "./SelectReseller";

const log = LOG.extend("EDIT_SHOP");

interface Inputs {
  name: string;
  code: string;
  street: string;
  city: string;
  zipCode: string;
  state: string;
  phoneNumber: string;
  vat: string;
  email: string;
  resellerId: string;
}

interface ShopDetailProps {
  shop: Shop;
}
const ShopDetail = (props: ShopDetailProps) => {
  const { shop } = props;
  const [edit, setEdit] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm<Inputs>({
    defaultValues: {
      name: shop?.name || "",
      code: shop?.code || "",
      street: shop?.addresses?.legal?.street || "",
      city: shop?.addresses?.legal?.city || "",
      zipCode: shop?.addresses?.legal?.zipCode || "",
      state: shop?.addresses?.legal?.state || "",
      phoneNumber: shop?.addresses?.legal?.phoneNumber || "",
      vat: shop?.vat || "",
      email: shop?.owner?.email || "",
      resellerId: shop?.reseller?._id || "",
    },
  });

  const [shopEdit, { error: shopEditError, loading: shopEditLoading }] =
    useMutation<ShopEditData, ShopEditVariables>(SHOP_EDIT);

  const onSubmit: SubmitHandler<Inputs> = async (inputs) => {
    const shopData = cleanApolloData(shop) as Shop;

    const { data } = await shopEdit({
      variables: {
        _id: shopData._id,
        shop: {
          name: inputs.name,
          vat: inputs.vat,
          reseller: inputs.resellerId || undefined,
          addresses: {
            commercial: shopData.addresses.commercial,
            legal: {
              city: inputs.city,
              state: inputs.state,
              zipCode: inputs.zipCode,
              phoneNumber: inputs.phoneNumber,
              street: inputs.street,
              denomination: shopData.addresses.legal.denomination,
              country: "IT",
            },
          },
        },
      },
    });

    if (!data?.ShopEdit) {
      log.error(" - shopEdit mutation - something went wrong");
      return;
    }

    reset({
      name: data.ShopEdit?.name || "",
      code: data.ShopEdit?.code || "",
      street: data.ShopEdit?.addresses?.legal?.street || "",
      city: data.ShopEdit?.addresses?.legal?.city || "",
      zipCode: data.ShopEdit?.addresses?.legal?.zipCode || "",
      state: data.ShopEdit?.addresses?.legal?.state || "",
      phoneNumber: data.ShopEdit?.addresses?.legal?.phoneNumber || "",
      vat: data.ShopEdit?.vat || "",
      email: data.ShopEdit?.owner?.email || "",
      resellerId: data.ShopEdit?.reseller?._id || "",
    });

    setEdit(false);
  };

  const onCancel = () => {
    reset();
    setEdit(false);
  };

  const renderEditButton = () => {
    if (edit) {
      return (
        <Flex direction={"row"} justify="end" mt={8}>
          <Button
            onClick={onCancel}
            colorScheme={"red"}
            mr={3}
            isLoading={shopEditLoading || isSubmitting}
            variant={"outline"}
          >
            Annulla
          </Button>
          <Button
            colorScheme="teal"
            type="submit"
            isLoading={shopEditLoading || isSubmitting}
            variant={"outline"}
          >
            Salva
          </Button>
        </Flex>
      );
    }
    return (
      <Flex direction={"row"} justify="end" gap={8} mt={8}>
        <Button variant={"outline"} onClick={() => setEdit(true)}>
          Modifica
        </Button>
      </Flex>
    );
  };

  return (
    <Box w="100%">
      <Card w="100%" h="full" bg="white" p={4}>
        <CardHeader>
          <Heading size="md">Dettagli Negozio</Heading>
        </CardHeader>
        <CardBody>
          {shopEditError ? (
            <ErrorText position="insideElement">{`${shopEditError}`}</ErrorText>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <SimpleGrid
                h="full"
                columns={{ base: 1, md: 2 }}
                spacingX={8}
                spacingY={2}
              >
                <FormInputText
                  label="Nome"
                  id="name"
                  isDisabled={!edit}
                  error={errors.name}
                  register={register("name", {
                    required: "* campo obbligatorio",
                  })}
                />
                <FormInputNumber
                  label="Codice"
                  id="code"
                  isDisabled
                  error={errors.code}
                  control={control}
                  rules={{
                    required: "* campo obbligatorio",
                  }}
                />
                <FormInputText
                  label="Via"
                  id="street"
                  isDisabled={!edit}
                  error={errors.street}
                  register={register("street", {
                    required: "* campo obbligatorio",
                  })}
                />
                <FormInputText
                  label="Città"
                  id="city"
                  isDisabled={!edit}
                  error={errors.city}
                  register={register("city", {
                    required: "* campo obbligatorio",
                  })}
                />
                <FormInputText
                  label="CAP"
                  id="zipCode"
                  isDisabled={!edit}
                  error={errors.zipCode}
                  register={register("zipCode", {
                    required: "* campo obbligatorio",
                  })}
                />
                <FormInputText
                  label="Provincia"
                  id="state"
                  isDisabled={!edit}
                  error={errors.state}
                  register={register("state", {
                    required: "* campo obbligatorio",
                  })}
                />
                <FormInputText
                  label="Telefono"
                  id="phoneNumber"
                  isDisabled={!edit}
                  error={errors.phoneNumber}
                  register={register("phoneNumber", {
                    required: "* campo obbligatorio",
                  })}
                />
                <FormInputText
                  label="P.IVA"
                  id="vat"
                  isDisabled={!edit}
                  error={errors.vat}
                  register={register("vat", {
                    required: "* campo obbligatorio",
                  })}
                />
                <FormInputText
                  label="Email proprietario"
                  id="email"
                  isDisabled
                  error={errors.email}
                  register={register("email")}
                />
                <SelectReseller
                  resellerId={watch("resellerId")}
                  label="Rivenditore"
                  placeholder="Seleziona..."
                  isDisabled={!edit}
                  onChange={(newValue) => {
                    setValue("resellerId", newValue || "");
                  }}
                />
              </SimpleGrid>
              {renderEditButton()}
            </form>
          )}
        </CardBody>
      </Card>
    </Box>
  );
};

export { ShopDetail };
