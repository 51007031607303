import { Navigate, Outlet } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { USER_LOGGED } from "../../stores/queries/appstate";
import { Navbar } from "../../components/Navbar";

const Admin = () => {
  const { data } = useQuery(USER_LOGGED);

  if (!data.userLogged) {
    return <Navigate replace to="/login" />;
  }

  return (
    <>
      <Navbar />
      <Outlet />
    </>
  );
};

export { Admin };
