import { mode } from "@chakra-ui/theme-tools";
import type { StyleFunctionProps } from "@chakra-ui/styled-system";

export const globalStyles = {
  colors: {
    primary: {
      "50": "#F0E8FD",
      "100": "#D4BFF8",
      "200": "#B895F4",
      "300": "#9C6CEF",
      "400": "#8042EB",
      "500": "#6419E6",
      "600": "#5014B8",
      "700": "#3C0F8A",
      "800": "#280A5C",
      "900": "#14052E",
    },
  },
  fonts: {
    heading: `'DM Sans', sans-serif`,
    body: `'DM Sans', sans-serif`,
  },
  styles: {
    global: (props: StyleFunctionProps) => ({
      body: {
        overflowX: "hidden",
        color: mode("gray.800", "whiteAlpha.900")(props),
        bg: mode("gray.100", "gray.900")(props),
        lineHeight: "base",
      },
    }),
  },
};
