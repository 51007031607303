import { extendTheme, ThemeConfig, withDefaultColorScheme } from "@chakra-ui/react";

import { globalStyles } from "./styles";
import { inputStyle } from "./components/input";
import { headingStyle } from "./components/heading";
import { modifyCardDefaultProps } from "./components/card";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

// Modify theme
const theme = extendTheme(
  globalStyles,
  inputStyle,
  // headingStyle,
  // cardStyle,
  {config},
  withDefaultColorScheme({ colorScheme: 'primary' }),
  );

  // Override componet cant be modify in theme
  modifyCardDefaultProps();


export { theme };
