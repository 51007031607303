import { Link } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";

const routes = [
  { path: "/", breadcrumb: "Home" },
  { path: "/newshop", breadcrumb: "Nuovo Negozio" },
  { path: "/processes", breadcrumb: "Processi" },
  { path: "/resellers", breadcrumb: "Rivenditori" },
  { path: "/resellers/new", breadcrumb: "Nuovo Rivenditore" },
  { path: "/duplicate", breadcrumb: null },
  { path: "/duplicate/:id", breadcrumb: "Duplica Negozio" },
  { path: "/shop", breadcrumb: null },
  { path: "/shop/:id", breadcrumb: "Negozio" },
  { path: "/shop/:id/lightorders", breadcrumb: "Lista Ordini Istantanei" },
  { path: "/shop/:id/orders", breadcrumb: "Lista Ordini" },
  { path: "/shop/:id/orders/:jobId", breadcrumb: "Json" },
];

const Breadcumbs = () => {
  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <Breadcrumb fontWeight={"medium"} fontSize={"sm"} color={"gray.600"}>
      {breadcrumbs.map(({ match, breadcrumb }, index) => (
        <BreadcrumbItem key={index}>
          <BreadcrumbLink as={"p"}>
            <Link to={match.pathname}>{breadcrumb}</Link>
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};

export { Breadcumbs };
