import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { LOG } from "../../../config";

import { Process, ROOT_PROCESSES } from "../../../stores/queries/process";

import { Box, Button, Card, CardBody, Flex, Heading } from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";

import { ErrorText, Table } from "../../../components/Ui";
import { Breadcumbs } from "../../../components/Breadcrumbs";

const log = LOG.extend("PROCESS_LIST");

const columns = [
  {
    id: "_id",
    name: "ID",
    minWidth: "20%",
    cell: (row: Process) => {
      return row._id;
    },
  },
  {
    id: "jobId",
    name: "Job",
    sortable: false,
    minWidth: "20%",
    cell: (row: Process) => {
      return row.job._id;
    },
  },
  {
    id: "jobStatus",
    name: "Stato Job",
    sortable: false,
    right: true,
    minWidth: "20%",
    cell: (row: Process) => {
      return row.jobStatus;
    },
  },
  {
    id: "paymentStatus",
    name: "Stato Pagamento",
    sortable: false,
    right: true,
    hide: "md",
    minWidth: "20%",
    cell: (row: Process) => {
      return row.paymentStatus;
    },
  },
];

const ProcessList = () => {
  const navigate = useNavigate();
  const { data, error, loading } = useQuery<{ RootProcesses: Process[] }>(
    ROOT_PROCESSES,
    {
      fetchPolicy: "cache-and-network",
      pollInterval: 5000,
    }
  );

  if (error) {
    log.error(error);
    return (
      <ErrorText position="fullPage">
        {error?.message || "GraphQL Error"}
      </ErrorText>
    );
  }

  return (
    <Box pos={"absolute"} mt={"6rem"} p={8} w="100%" h="calc(100vh - 6rem)">
      <Box mb={8} px={4}>
        <Flex direction="row" align={"center"} justify={"space-between"}>
          <Box>
            <Breadcumbs />
            <Heading size={"xl"} mb={2}>
              {`Processi in Corso`}
            </Heading>
            <Button
              leftIcon={<ArrowBackIcon />}
              variant="link"
              colorScheme={"gray"}
              onClick={() => navigate(-1)}
            >
              Home
            </Button>
          </Box>
        </Flex>
      </Box>
      <Box w="100%">
        <Card w="100%" h="full" bg="white" p={4}>
          <CardBody>
            <Table
              columns={columns}
              data={data?.RootProcesses || []}
              loading={loading}
              rowNotClickable
            />
          </CardBody>
        </Card>
      </Box>
    </Box>
  );
};

export { ProcessList };
