import { gql } from "@apollo/client";
import { type } from "os";

interface BusinessAddress {
  country: "IT";
  city: string;
  state: string;
  zipCode: string;
  street: string;
  vat: string;
  sdi: string;
}

interface ResellerManger {
  name: string;
  phone: string;
  email: string;
}

interface Reseller {
  _id: string;
  name: string;
  manager: ResellerManger;
  phone: string;
  email: string;
  pec: string;
  address: BusinessAddress;
  commission: number;
}

const RESELLER_FRAGMENT = gql`
  fragment resellerFragment on Reseller {
    _id
    name
    manager {
      name
      phone
      email
    }
    phone
    email
    pec
    address {
      country
      city
      state
      zipCode
      street
      vat
      sdi
    }
    commission
  }
`;

const RESELLERS = gql`
  ${RESELLER_FRAGMENT}
  query RESELLERS {
    Resellers {
      ...resellerFragment
    }
  }
`;

/* --------------------------------------------------------------------------*/
/* ----------------------  MUTATION  ----------------------------------------*/
/* --------------------------------------------------------------------------*/

const RESELLER_CREATE = gql`
  ${RESELLER_FRAGMENT}
  mutation CREATE_RESELLER($reseller: ResellerInput!) {
    ResellerCreate(reseller: $reseller) {
      ...resellerFragment
    }
  }
`;

interface ResellerCreateData {
  ResellerCreate: Reseller;
}

interface ResellerCreateVariables {
  reseller: {
    name: string;
    manager: ResellerManger;
    phone: string;
    email: string;
    pec: string;
    address: BusinessAddress;
    commission: number;
  };
}

export { RESELLERS, RESELLER_CREATE };
export type {
  Reseller,
  ResellerManger,
  BusinessAddress,
  ResellerCreateData,
  ResellerCreateVariables,
};
