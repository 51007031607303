import DataTable from "react-data-table-component";
import { customTableStyle } from "../../../styles/components/table";

import { Spinner } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  ConditionalStyles,
  PaginationChangePage,
} from "react-data-table-component/dist/src/DataTable/types";

interface TableProps {
  columns: any;
  data: any[];
  title?: string;
  loading?: boolean;
  selectableRows?: boolean;
  defaultSortFieldId?: string;
  defaultSortInvert?: boolean;
  rowNotClickable?: boolean;
  onRowClick?: (row: any, e: any) => void;
  pagination?: boolean;
  paginationServer?: boolean;
  paginationDefaultPage?: number;
  paginationPerPage?: number;
  onChangePage?: PaginationChangePage;
  paginationTotalRows?: number;
  conditionalRowStyles?: ConditionalStyles<any>[];
}

const Table = (props: TableProps) => {
  let paginationComponentOptions = undefined;
  if (props.pagination) {
    paginationComponentOptions = {
      rowsPerPageText: "Elementi per pagina",
      rangeSeparatorText: "di",
      noRowsPerPage: true,
      selectAllRowsItem: true,
      selectAllRowsItemText: "Tutti",
    };
  }

  return (
    <DataTable
      columns={props.columns}
      data={props.data || []}
      responsive={true}
      progressPending={props.loading}
      selectableRows={props.selectableRows}
      defaultSortFieldId={props.defaultSortFieldId}
      defaultSortAsc={props.defaultSortInvert}
      pointerOnHover={!props.rowNotClickable}
      highlightOnHover={!props.rowNotClickable}
      onRowClicked={props.onRowClick}
      striped
      fixedHeader
      pagination={props.pagination}
      paginationDefaultPage={props.paginationDefaultPage}
      paginationPerPage={props.paginationPerPage}
      paginationComponentOptions={paginationComponentOptions}
      paginationServer={props.paginationServer}
      onChangePage={props.onChangePage}
      paginationTotalRows={props.paginationTotalRows}
      sortIcon={<ChevronDownIcon />}
      customStyles={customTableStyle}
      conditionalRowStyles={props.conditionalRowStyles}
      progressComponent={
        <Spinner
          speed="0.6s"
          emptyColor="purple.200"
          color="purple.400"
          size="lg"
        />
      }
    />

    // <Box w="100%">
    //   <Card w="100%" h="full" bg="white" p={4}>
    //     {props.title && (
    //       <CardHeader>
    //         <Heading size="md">{props.title}</Heading>
    //       </CardHeader>
    //     )}
    //     <CardBody>
    //       <Box>
    //         <DataTable
    //           columns={props.columns}
    //           data={props.data || []}
    //           responsive={true}
    //           progressPending={props.loading}
    //           selectableRows={props.selectableRows}
    //           defaultSortFieldId={props.defaultSortFieldId}
    //           defaultSortAsc={props.defaultSortInvert ? false : true}
    //           onRowClicked={props.onRowClick}
    //           striped
    //           fixedHeader
    //           sortIcon={<ChevronDownIcon />}
    //           customStyles={customTableStyle}
    //         />
    //       </Box>
    //     </CardBody>
    //   </Card>
    // </Box>
  );
};

export { Table };
