import { useMutation } from "@apollo/client";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Button,
  Text,
  Box,
} from "@chakra-ui/react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormInputNumber } from "../../../../../components/Ui/FormInputNumber";
import { LOG } from "../../../../../config";
import { numberToEuro } from "../../../../../libs/utils";
import {
  ShopUpdateCreditsData,
  ShopUpdateCreditsVariables,
  SHOP_UPDATE_CREDITS,
} from "../../../../../stores/queries/shop";

const log = LOG.extend("EDIT_SHOP");

interface Inputs {
  credits: string;
}
interface AddCreditsModalProps {
  credits: number | null;
  shopId: string;
}
const AddCreditsModal = (props: AddCreditsModalProps) => {
  const { credits, shopId } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>({
    defaultValues: {
      credits: "",
    },
  });

  const [shopUpdateCredits, { error, loading }] = useMutation<
    ShopUpdateCreditsData,
    ShopUpdateCreditsVariables
  >(SHOP_UPDATE_CREDITS);

  const onSubmit: SubmitHandler<Inputs> = async (inputs) => {
    log.debug("CALL onSubit()");
    if (!Number(inputs.credits)) {
      log.warn(`can NOT increment credit of ${inputs.credits}`);
      return;
    }

    const { data } = await shopUpdateCredits({
      variables: {
        _id: shopId,
        credits: Number(inputs.credits),
      },
    });

    if (!data?.RootShopServicePaymentUpdateCredit) {
      log.error(" - shopUpdateCredits mutation - something went wrong");
      return;
    }

    log.info(
      ` - update shop credits = ${credits} + ${Number(inputs.credits)} `
    );

    reset();
    onClose();
  };

  const onCancel = () => {
    reset();
    onClose();
  };

  return (
    <>
      <Button variant={"outline"} onClick={onOpen}>
        Modifica
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Aggiungi Crediti</ModalHeader>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>
              <Text textAlign={"right"}>{`Credito attuale: ${numberToEuro(
                credits
              )}`}</Text>
              <Box mt={2} mb={4}>
                <FormInputNumber
                  label="Credito da aggiungere"
                  id="credits"
                  error={errors.credits}
                  control={control}
                  rules={{
                    required: "* campo obbligatorio",
                  }}
                />
              </Box>
              <Text textAlign={"right"}>
                {`Credito finale: ${numberToEuro(
                  Number(watch("credits")) + Number(credits)
                )}`}
              </Text>
            </ModalBody>

            <ModalFooter>
              <Button
                variant={"outline"}
                colorScheme="red"
                mr={3}
                onClick={onCancel}
                isLoading={loading || isSubmitting}
              >
                Annulla
              </Button>
              <Button
                variant={"outline"}
                colorScheme="teal"
                type="submit"
                isLoading={loading || isSubmitting}
              >
                Conferma
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export { AddCreditsModal };
