import { ThemeOverride, ComponentStyleConfig } from "@chakra-ui/react";

export const inputStyle: ThemeOverride = {
  components: {
    Input: {
      defaultProps: {
        focusBorderColor: 'primary.400',
      },
    },
  },
};

export const inputNewStyle: ComponentStyleConfig = {
      defaultProps: {
        focusBorderColor: 'primary.400',
      },
 };