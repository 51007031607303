import { useQuery } from "@apollo/client";
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react";
import { ErrorText, Table } from "../../../components/Ui";
import { LOG } from "../../../config";
import { numberToEuro } from "../../../libs/utils";
import {
  Credit,
  CREDITS_LABEL,
  CREDIT_HISTORY,
} from "../../../stores/queries/credits";

const log = LOG.extend("EDIT_SHOP");

const columns = [
  {
    id: "creationDate",
    name: "Data",
    minWidth: "15%",
    cell: (row: Credit) => {
      if (!row.creationDate) return "-";
      let date = new Date(row.creationDate);
      let dateString = `${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`;
      return dateString;
    },
  },
  {
    id: "scope",
    name: "Operazione",
    sortable: false,
    minWidth: "15%",
    cell: (row: Credit) => {
      let labelObj = CREDITS_LABEL.find((s) => s.value === row.scope);
      let label = labelObj ? labelObj.label : "Sconosciuta";
      return label;
    },
  },
  {
    id: "oldAmount",
    name: "Vecchio Credito",
    sortable: false,
    right: true,
    hide: "md",
    minWidth: "10%",
    cell: (row: Credit) => {
      return numberToEuro(row.oldAmount);
    },
  },
  {
    id: "newAmount",
    name: "Nuovo Credito",
    sortable: false,
    right: true,
    hide: "md",
    minWidth: "10%",
    cell: (row: Credit) => {
      return numberToEuro(row.newAmount);
    },
  },
  {
    id: "amount",
    name: "Valore",
    sortable: false,
    right: true,
    minWidth: "10%",
    cell: (row: Credit) => {
      let color = "green.400";
      if (
        row.scope === "dischargedByOperation" ||
        row.scope === "dischargedByRoot"
      ) {
        color = "red.400";
      }
      return (
        <div style={{ pointerEvents: "none" }}>
          <Text color={color} fontWeight={"bold"}>
            {numberToEuro(row.amount)}
          </Text>
        </div>
      );
    },
  },
];

interface CreditsDetailProps {
  shopId: string;
}
const CreditsDetail = (props: CreditsDetailProps) => {
  const { shopId } = props;
  const { data, error, loading } = useQuery<
    { RootGetShopCreditHistory: Credit[] },
    { shopId: string }
  >(CREDIT_HISTORY, {
    variables: {
      shopId: shopId,
    },
    fetchPolicy: "cache-and-network",
  });

  if (error) {
    log.error(JSON.stringify(error));
  }

  return (
    <Box w="100%">
      <Card w="100%" h="full" bg="white" p={4}>
        <CardHeader>
          <Heading size="md">Storico Crediti</Heading>
        </CardHeader>
        <CardBody>
          <Flex
            direction={"column"}
            position={"relative"}
            w="full"
            maxH={"calc(100vh - 350px)"}
            overflow={"auto"}
          >
            {error ? (
              <ErrorText position="insideElement">{`${error}`}</ErrorText>
            ) : (
              <Table
                columns={columns}
                data={data?.RootGetShopCreditHistory || []}
                loading={loading}
                rowNotClickable
              />
            )}
          </Flex>
        </CardBody>
      </Card>
    </Box>
  );
};

export { CreditsDetail };
