import { gql } from "@apollo/client";
import { Job, JobPaymentStatus, JobStatus } from "./job";

interface Process {
  _id: string;
  job: Job;
  jobStatus: JobStatus;
  paymentStatus: JobPaymentStatus;
}

const PROCESS_FRAGMENT = gql`
  fragment processFragment on Process {
    _id
    jobStatus
    paymentStatus
    job {
      _id
    }
  }
`;

const ROOT_PROCESSES = gql`
  ${PROCESS_FRAGMENT}
  query ROOT_PROCESSES {
    RootProcesses {
      ...processFragment
    }
  }
`;

export { ROOT_PROCESSES };
export type { Process };
