import { ArrowBackIcon } from "@chakra-ui/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  Box,
  Heading,
  Button,
  Center,
  Spinner,
  Card,
  CardBody,
  Text,
  IconButton,
  Flex,
} from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";
import { ErrorText, Table } from "../../../components/Ui";
import { LOG } from "../../../config";
import { Breadcumbs } from "../../../components/Breadcrumbs";
import { useState } from "react";
import {
  LightJob,
  LightJobsData,
  LightJobsVariable,
  LIGHT_JOBS,
} from "../../../stores/queries/lightJob";
import { downloadURI } from "../../../libs/utils";

const log = LOG.extend("LIGHT_JOB_LIST");

const LightJobList = () => {
  const params: any = useParams();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  const columns = [
    {
      id: "creationDate",
      name: "Ricevuto il",
      selector: (row: LightJob) => row.createdAt,
      sortable: true,
      minWidth: "15%",
      cell: (row: LightJob) => {
        if (!row.createdAt) return "-";
        let date = new Date(row.createdAt);
        let dateString = `${date.toLocaleDateString()}`;
        let timeString = `${date.toLocaleTimeString()}`;
        return (
          <div style={{ pointerEvents: "none" }}>
            <Text>{dateString}</Text>
            <Text>{timeString}</Text>
          </div>
        );
      },
    },
    {
      id: "user",
      name: "Cliente",
      selector: (row: LightJob) => {
        let name = row.userData.name;
        return name || "-";
      },
      sortable: true,
      minWidth: "15%",
      cell: (row: LightJob) => {
        let name = row.userData.name;
        return (
          <div style={{ pointerEvents: "none" }}>
            <Text fontWeight={"semibold"}>{name || "-"}</Text>
          </div>
        );
      },
    },
    {
      id: "code",
      name: "Code",
      minWidth: "15%",
      cell: (row: LightJob) => {
        return row.code;
      },
    },
    {
      id: "images",
      name: "Num. Immagini",
      center: true,
      minWidth: "15%",
      cell: (row: LightJob) => {
        return row.images.length;
      },
    },
    {
      id: "actions",
      name: "Azioni",
      center: true,
      cell: (row: LightJob) => {
        let disabled = row.downloadUrl ? false : true;
        return (
          <IconButton
            disabled={disabled}
            size={"xs"}
            variant={"outline"}
            aria-label="Downalod"
            icon={<DownloadIcon />}
            onClick={() => {
              if (row.downloadUrl) {
                downloadURI(row.downloadUrl, row._id);
                log.info(`- DOWNLOAD JOB: ${row._id}`);
              } else {
                log.warn(`- can NOT find downloadUrl of job: ${row._id}`);
              }
            }}
          />
        );
      },
    },
  ];

  const JOBS_FOR_PAGES = 30;

  const { loading, error, data } = useQuery<LightJobsData, LightJobsVariable>(
    LIGHT_JOBS,
    {
      variables: {
        shopId: params.id || "",
        offset: (page - 1) * JOBS_FOR_PAGES,
        limit: JOBS_FOR_PAGES,
      },
      fetchPolicy: "cache-and-network",
      pollInterval: 5000,
    }
  );

  if (error) {
    log.error(error);
    return (
      <ErrorText position="fullPage">
        {error?.message || "GraphQL Error"}
      </ErrorText>
    );
  }

  const renderLightJobList = () => {
    if (!data?.AdminLightJobs) {
      return (
        <Center>
          <Spinner
            speed="0.6s"
            emptyColor="purple.200"
            color="purple.400"
            size="lg"
          />
        </Center>
      );
    }
    return (
      <Box w="100%">
        <Card w="100%" h="full" bg="white" p={4}>
          <CardBody>
            <Flex
              direction={"column"}
              position={"relative"}
              w="full"
              maxH={"calc(100vh - 350px)"}
              overflow={"auto"}
            >
              <Table
                columns={columns}
                data={data?.AdminLightJobs.lightJobs || []}
                loading={loading}
                rowNotClickable
                pagination
                paginationServer
                paginationPerPage={JOBS_FOR_PAGES}
                paginationTotalRows={data?.AdminLightJobs.count}
                paginationDefaultPage={page}
                onChangePage={(page) => {
                  log.debug(`change page to ${page}`);
                  setPage(page);
                }}
              />
            </Flex>
          </CardBody>
        </Card>
      </Box>
    );
  };
  return (
    <Box pos={"absolute"} mt={"6rem"} p={8} w="100%" h="calc(100vh - 6rem)">
      <Box mb={8} px={4}>
        <Breadcumbs />
        <Heading size={"xl"} mb={2}>
          {`Lista Ordini Istantanei - ${
            data?.AdminLightJobs?.lightJobs?.[0]?.shop?.name || ""
          }`}
        </Heading>
        <Button
          leftIcon={<ArrowBackIcon />}
          variant="link"
          colorScheme={"gray"}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      </Box>
      {renderLightJobList()}
    </Box>
  );
};

export { LightJobList };
