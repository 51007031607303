import { gql } from "@apollo/client";
import { Shop } from "./shop";

interface LightJob {
  _id: string;
  shop: Shop;
  userData: {
    name: string;
  };
  code: string;
  createdAt: string; // Date ISO Format es: "2021-03-01T00:00:00.000Z"
  downloadUrl?: string;
  images: {
    url: string;
    _id: string;
  }[];
}

const LIGHT_JOB_FRAGMENT = gql`
  fragment lightJobFragment on LightJob {
    _id
    shop {
      _id
      name
    }
    userData {
      name
    }
    code
    createdAt
    downloadUrl
    images {
      url
      _id
    }
  }
`;

const LIGHT_JOB = gql`
  ${LIGHT_JOB_FRAGMENT}
  query LIGHT_JOB($_id: ID!) {
    AdminLightJob(_id: $_id) {
      ...lightJobFragment
    }
  }
`;

const LIGHT_JOBS = gql`
  ${LIGHT_JOB_FRAGMENT}
  query LIGHT_JOBS($shopId: ID!, $limit: Int, $offset: Int) {
    AdminLightJobs(shopId: $shopId, limit: $limit, offset: $offset) {
      lightJobs {
        ...lightJobFragment
      }
      count
    }
  }
`;

interface LightJobsData {
  AdminLightJobs: {
    lightJobs: LightJob[];
    count: number;
  };
}

interface LightJobsVariable {
  shopId: string;
  limit?: number;
  offset?: number;
}

export { LIGHT_JOB, LIGHT_JOBS };
export type { LightJob, LightJobsData, LightJobsVariable };
