import { ArrowBackIcon } from "@chakra-ui/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Job, JOB } from "../../../stores/queries/job";
import { cleanApolloData } from "../../../libs/utils";
import { LOG } from "../../../config";
import ReactJson from "react-json-view";
import {
  Box,
  Heading,
  Button,
  Center,
  Spinner,
  Card,
  CardBody,
  Flex,
} from "@chakra-ui/react";
import { ErrorText } from "../../../components/Ui";
import { Breadcumbs } from "../../../components/Breadcrumbs";

const log = LOG.extend("ORDER_JSON");

const OrderJson = () => {
  const params: any = useParams();
  const navigate = useNavigate();

  const { loading, error, data } = useQuery<{ AdminJob: Job }, { _id: string }>(
    JOB,
    {
      variables: {
        _id: params.jobId || "",
      },
      fetchPolicy: "cache-and-network",
    }
  );

  if (error) {
    log.error(error);
    return (
      <ErrorText position="fullPage">
        {error?.message || "GraphQL Error"}
      </ErrorText>
    );
  }

  const renderJson = () => {
    if (!data?.AdminJob) {
      return (
        <Center>
          <Spinner
            speed="0.6s"
            emptyColor="purple.200"
            color="purple.400"
            size="lg"
          />
        </Center>
      );
    }

    return (
      <Box w="100%">
        <Card w="100%" h="full" bg="white" p={4}>
          <CardBody>
            <Flex
              direction={"column"}
              position={"relative"}
              w="full"
              maxH={"calc(100vh - 350px)"}
              overflow={"auto"}
            >
              <ReactJson
                src={cleanApolloData(data.AdminJob) || {}}
                displayDataTypes={false}
                quotesOnKeys={false}
                enableClipboard={false}
              />
            </Flex>
          </CardBody>
        </Card>
      </Box>
    );
  };

  return (
    <Box pos={"absolute"} mt={"6rem"} p={8} w="100%" h="calc(100vh - 6rem)">
      <Box mb={8} px={4}>
        <Breadcumbs />
        <Heading size={"xl"} mb={2}>
          {`Ordine: ${data?.AdminJob?._id || ""}`}
        </Heading>
        <Button
          leftIcon={<ArrowBackIcon />}
          variant="link"
          colorScheme={"gray"}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      </Box>
      {renderJson()}
    </Box>
  );
};

export { OrderJson };
