import { logger, configLoggerType, consoleTransport } from "react-native-logs";
import { deviceInfo } from "./deviceInfo";

const sessionId = "SES_" + new Date().getTime();

let __DEV__ = true;
if (process.env.REACT_APP_ENV === "prod") {
  __DEV__ = false;
}

// VARIABILI D'AMBIENTE

interface EnvVars {
  mode: "test" | "production";
  basename: string;
  deviceInfo: {
    system: string;
    app: {
      version: string;
      name: string;
    };
  };
  backend: {
    uri: string;
  };
  auth: {
    sessionId: string;
    uri: string;
  };
  db: {
    cacheKey: string;
    authKey: string;
  };
}

const ENV_VAR_TEST: EnvVars = {
  mode: "test",
  basename: "apptest",
  deviceInfo,
  backend: {
    uri: "https://test.backend.easypixy.com/graphql",
  },
  auth: {
    sessionId: sessionId,
    uri: "https://test.auth.easypixy.com",
  },
  db: {
    cacheKey: "EYPY_AD_CACHE_TEST",
    authKey: "EYPY_AD_AUTH_TEST",
  },
};

const ENV_VAR_PRODUCTION: EnvVars = {
  mode: "production",
  basename: "app",
  deviceInfo,
  backend: {
    uri: "https://backend.easypixy.com/graphql",
  },
  auth: {
    sessionId: sessionId,
    uri: "https://auth.easypixy.com",
  },
  db: {
    cacheKey: "EYPY_AD_CACHE_PROD",
    authKey: "EYPY_AD_AUTH_PROD",
  },
};

const ENV: EnvVars = __DEV__ ? ENV_VAR_TEST : ENV_VAR_PRODUCTION;

// LOGGER

const loggerConfig: configLoggerType = {
  transport: consoleTransport,
  severity: __DEV__ ? "debug" : "warn",
  transportOptions: {
    colors: {
      info: "blueBright",
      warn: "yellow",
      error: "redBright",
    },
  },
  levels: {
    debug: 0,
    info: 1,
    warn: 2,
    error: 3,
  },
  async: true,
  dateFormat: "time",
  printLevel: true,
  printDate: true,
  enabled: true,
  enabledExtensions: [
    "STORAGES",
    "AUTHLIB",
    "APOLLOCLIENT",
    "API",
    "ORDER_LIST",
    "ADMIN",
    "ORDER_JSON",
    "EDIT_SHOP",
    "SHOP_LIST",
    "RESELLER_LIST",
    "CREATE_SHOP",
    "CREATE_RESELLER",
    "EDIT_RESELLER",
    "PROCESS_LIST",
    "DUPLICATE_SHOP",
    "LIGHT_JOB_LIST",
  ],
};

const LOG = logger.createLogger(loggerConfig);

// LOG AVVIO APP
if (__DEV__) {
  LOG.debug("PMA started in Development");
}

LOG.warn(
  `NEW SESSION | v. ${ENV.deviceInfo.app.version} | ${ENV.deviceInfo.system} | ENV: ${ENV.mode}`
);

export { ENV, LOG, __DEV__ };
