import { useNavigate, useParams } from "react-router-dom";

import { useQuery } from "@apollo/client";
import { Shop, SHOP } from "../../../stores/queries/shop";

import { ShopDetail } from "./ShopDetail";

import {
  Box,
  Heading,
  Center,
  Spinner,
  Button,
  VStack,
  Flex,
  HStack,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";

import { LOG } from "../../../config";
import { ErrorText } from "../../../components/Ui";
import { ServiceDetail } from "./ServiceDetail";
import { FeesDetail } from "./FeesDetail";
import { Breadcumbs } from "../../../components/Breadcrumbs";
import { CreditsDetail } from "./CreditsDetail";

const log = LOG.extend("EDIT_SHOP");

const EditShop = () => {
  const params: any = useParams();
  const navigate = useNavigate();

  const { data, error, loading } = useQuery<{ Shop: Shop }, { _id: string }>(
    SHOP,
    {
      variables: { _id: params.id },
      fetchPolicy: "cache-and-network",
    }
  );

  if (error) {
    log.error(error);
    return (
      <ErrorText position="fullPage">
        {error?.message || "GraphQL Error"}
      </ErrorText>
    );
  }

  const renderShopCards = () => {
    if (!data?.Shop) {
      return (
        <Center>
          <Spinner
            speed="0.6s"
            emptyColor="purple.200"
            color="purple.400"
            size="lg"
          />
        </Center>
      );
    }

    return (
      <VStack spacing={8}>
        <ServiceDetail
          payments={data.Shop.servicePayments}
          shopId={data.Shop._id}
          isEnabled={data.Shop.isEnabled}
          isMaintenance={data.Shop.isMaintenance}
          type={data.Shop.type}
        />
        <ShopDetail shop={data.Shop} />
        <FeesDetail
          fees={data.Shop.servicePayments.fees}
          shopId={data.Shop._id}
        />
        <CreditsDetail shopId={data.Shop._id} />
      </VStack>
    );
  };

  return (
    <Box pos={"absolute"} mt={"6rem"} p={8} w="100%" h="calc(100vh - 6rem)">
      <Box mb={8} px={4}>
        <Flex direction="row" align={"center"} justify={"space-between"}>
          <Box>
            <Breadcumbs />
            <Heading size={"xl"} mb={2}>
              {`Negozio - ${data?.Shop?.name || data?.Shop?.code}`}
            </Heading>
            <Button
              leftIcon={<ArrowBackIcon />}
              variant="link"
              colorScheme={"gray"}
              onClick={() => navigate(-1)}
            >
              Home
            </Button>
          </Box>
          <HStack gap={2}>
            <Button
              variant="outline"
              onClick={() => navigate("/shop/" + params.id + "/orders")}
            >
              Lista Ordini
            </Button>
            <Button
              variant="outline"
              onClick={() => navigate("/shop/" + params.id + "/lightorders")}
            >
              Lista Ordini Light
            </Button>
          </HStack>
        </Flex>
      </Box>
      {renderShopCards()}
      <Box py={4}></Box>
    </Box>
  );
};

export { EditShop };
