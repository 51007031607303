import { useNavigate } from "react-router-dom";

import { Box, Heading, Button, Flex } from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";

import { LOG } from "../../../config";
import { Breadcumbs } from "../../../components/Breadcrumbs";
import { CreateResellerDetail } from "./CreateResellerDetail";

const log = LOG.extend("CREATE_RESELLER");

const CreateReseller = () => {
  const navigate = useNavigate();

  return (
    <Box pos={"absolute"} mt={"6rem"} p={8} w="100%" h="calc(100vh - 6rem)">
      <Box mb={8} px={4}>
        <Flex direction="row" align={"center"} justify={"space-between"}>
          <Box>
            <Breadcumbs />
            <Heading size={"xl"} mb={2}>
              {`Crea Nuovo Rivenditore`}
            </Heading>
            <Button
              leftIcon={<ArrowBackIcon />}
              variant="link"
              colorScheme={"gray"}
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
          </Box>
        </Flex>
      </Box>
      <CreateResellerDetail />
      <Box py={4}></Box>
    </Box>
  );
};

export { CreateReseller };
