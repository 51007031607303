import React, { useEffect, useState } from "react";
import { Shop, SHOPS } from "../../../stores/queries/shop";
import { useQuery } from "@apollo/client";
import { LOG, __DEV__ } from "../../../config";

import {
  Box,
  Heading,
  Button,
  Card,
  CardBody,
  Flex,
  HStack,
  IconButton,
  Text,
  InputGroup,
  Input,
  InputLeftElement,
} from "@chakra-ui/react";
import {
  AddIcon,
  AtSignIcon,
  CopyIcon,
  ExternalLinkIcon,
  InfoOutlineIcon,
  PhoneIcon,
  SearchIcon,
} from "@chakra-ui/icons";
import { ErrorText, Table } from "../../../components/Ui";
import { Media, TableColumn } from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { Breadcumbs } from "../../../components/Breadcrumbs";
import { openExternalLink } from "../../../libs/utils";

const log = LOG.extend("SHOP_LIST");

const ShopList = () => {
  const navigate = useNavigate();
  const { data, error, loading } = useQuery<{ Shops: Shop[] }>(SHOPS, {
    fetchPolicy: "cache-and-network",
    pollInterval: 5000,
  });
  const [filteredShops, setFilteredShops] = useState<Shop[]>([]);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    if (data?.Shops) {
      setFilteredShops(
        data.Shops.filter((shop) => {
          if (search === "") {
            return true;
          }
          return shop.name.toLowerCase().includes(search.toLowerCase());
        })
      );
    }
  }, [data, search]);

  const columns: TableColumn<Shop>[] = [
    {
      name: "nome",
      selector: (row: Shop) => row.name,
      sortable: true,
    },
    {
      name: "code",
      selector: (row: Shop) => row.code,
      cell: (row: Shop) => row.code,
    },
    {
      name: "Proprietario",
      selector: (row: Shop) => row.owner.username,
      hide: Media.MD,
      sortable: true,
      cell: (row: Shop) => {
        return row.owner?.basicInfo?.name || row.owner?.username || "-";
      },
    },
    {
      name: "Fatturato",
      selector: (row: Shop) => row.servicePayments.earning || 0,
      sortable: true,
      hide: Media.MD,
      cell: (row: Shop) => {
        if (!row.servicePayments.earning) {
          return "Sconosciuto";
        }
        return `${Math.round(row.servicePayments.earning * 100) / 100} €`;
      },
    },
    {
      name: "Abilitazione",
      selector: (row: Shop) => row.isEnabled,
      sortable: true,
      cell: (row: Shop) => {
        return row.isEnabled ? "Sì" : "No";
      },
    },
    {
      name: "Manutenzione",
      selector: (row: Shop) => row.isMaintenance,
      sortable: true,
      cell: (row: Shop) => {
        return row.isMaintenance ? "Sì" : "No";
      },
    },
    {
      name: "Tipologia",
      selector: (row: Shop) => row.type,
      sortable: true,
      cell: (row: Shop) => {
        return row.type === "full" ? "Full" : "Light";
      },
    },
    {
      id: "actions",
      name: "Azioni",
      hide: Media.MD,
      center: true,
      ignoreRowClick: true,
      cell: (row: Shop) => {
        if (row.status === "copying") {
          return (
            <Text color={"purple.600"} fontWeight={"bold"}>
              in copia...
            </Text>
          );
        }
        if (row.status === "removing") {
          return (
            <Text color={"purple.600"} fontWeight={"bold"}>
              in rimozione...
            </Text>
          );
        }
        return (
          <IconButton
            size={"xs"}
            variant={"outline"}
            aria-label="Detail"
            icon={<CopyIcon />}
            onClick={() => {
              navigate("/duplicate/" + row._id);
            }}
          />
        );
      },
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row: Shop) => row.status !== "running",
      style: {
        opacity: 0.4,
        cursor: "auto",
      },
    },
  ];

  if (error) {
    log.error(error);
    return (
      <ErrorText position="fullPage">
        {error?.message || "GraphQL Error"}
      </ErrorText>
    );
  }

  return (
    <Box pos={"absolute"} mt={"6rem"} p={8} w="100%" h="calc(100vh - 6rem)">
      <Box mb={8} px={4}>
        <Flex direction="row" align={"center"} justify={"space-between"}>
          <Box>
            <Breadcumbs />
            <Heading size={"xl"} mb={2}>
              Lista Negozi
            </Heading>
          </Box>
          <HStack gap={2}>
            <Button
              variant="outline"
              onClick={() => navigate("/newshop")}
              rightIcon={<AddIcon />}
            >
              Crea negozio
            </Button>
            <Button
              variant="outline"
              onClick={() => navigate("/resellers")}
              rightIcon={<AtSignIcon />}
            >
              Rivenditori
            </Button>
            <Button
              variant="outline"
              onClick={() => navigate("/processes")}
              rightIcon={<InfoOutlineIcon />}
            >
              Processi
            </Button>
            {/* <Button
              variant="outline"
              rightIcon={<ExternalLinkIcon />}
              onClick={() => {
                openExternalLink(
                  __DEV__
                    ? "https://test.shop.easypixy.com/admin"
                    : "https://shop.easypixy.com/admin",
                  true
                );
              }}
            >
              Business Dash
            </Button> */}
            <Box>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.400" />
                </InputLeftElement>
                <Input
                  borderColor={"primary.400"}
                  _hover={{ backgroundColor: "primary.50" }}
                  variant={"outline"}
                  placeholder="Cerca per Nome..."
                  onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
            </Box>
          </HStack>
        </Flex>
      </Box>
      <Box w="100%">
        <Card w="100%" h="full" bg="white" p={4}>
          <CardBody>
            <Table
              columns={columns}
              data={filteredShops}
              loading={loading}
              conditionalRowStyles={conditionalRowStyles}
              onRowClick={(row: Shop, event) => {
                if (row.status === "running") {
                  navigate("/shop/" + row._id);
                }
              }}
            />
          </CardBody>
        </Card>
      </Box>
    </Box>
  );
};

export { ShopList };
