import { ReactNode } from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  InputGroup,
} from "@chakra-ui/react";
import { UseFormRegisterReturn, FieldError } from "react-hook-form";

interface FormInputTextProps {
  id: string;
  register: UseFormRegisterReturn;
  type?: "text" | "email" | "password";
  label?: string;
  placeholder?: string;
  error?: FieldError;
  isDisabled?: boolean;
  size?: "xs" | "sm" | "md" | "lg" | "xs";
  textAlign?: "left" | "center" | "right";
  leftElement?: ReactNode;
  rightElement?: ReactNode;
}

const FormInputText = (props: FormInputTextProps) => {
  const {
    id,
    register,
    label,
    type,
    placeholder,
    error,
    isDisabled,
    size,
    textAlign,
    leftElement,
    rightElement,
  } = props;
  return (
    <FormControl isInvalid={Boolean(error)}>
      <FormLabel textAlign={textAlign}>{label || null}</FormLabel>
      <InputGroup>
        {leftElement && leftElement}
        <Input
          key={id}
          id={id}
          type={type || "text"}
          placeholder={placeholder}
          isDisabled={isDisabled}
          size={size}
          textAlign={textAlign}
          {...register}
        />
        {rightElement && rightElement}
      </InputGroup>
      <FormErrorMessage>{error && error.message}</FormErrorMessage>
    </FormControl>
  );
};

export { FormInputText };
