import { useState } from "react";
import {
  Shop,
  ShopUpdateFeesData,
  ShopUpdateFeesVariables,
  SHOP_UPDATE_FEES,
} from "../../../stores/queries/shop";
import { useMutation } from "@apollo/client";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Flex,
  Button,
  VStack,
  StackDivider,
  IconButton,
  InputRightElement,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { LOG } from "../../../config";
import { FormInputNumber } from "../../../components/Ui/FormInputNumber";

const log = LOG.extend("EDIT_SHOP");

interface Inputs {
  fees: {
    earningLimit: string;
    percentualFee: string;
  }[];
}

const feesToString = (
  fees: Shop["servicePayments"]["fees"]
): Inputs["fees"] => {
  let stringFees: Inputs["fees"] = [];
  for (let i = 0; i < fees.length; i++) {
    const fee = fees[i];
    if (!fee.earningLimit || !fee.percentualFee) {
      log.error(
        `feesToString() - fee[${i}] han NOT earningLimit or percentualFee`
      );
      continue;
    }
    stringFees.push({
      earningLimit: fee.earningLimit.toString(),
      percentualFee: fee.percentualFee.toString(),
    });
  }
  return stringFees;
};

const feesToNumber = (
  stringFees: Inputs["fees"]
): Shop["servicePayments"]["fees"] => {
  let numberFees: Shop["servicePayments"]["fees"] = [];
  for (let i = 0; i < stringFees.length; i++) {
    const fee = stringFees[i];
    if (!fee.earningLimit || !fee.percentualFee) {
      log.error(
        `feesToNumber() - fee[${i}] han NOT earningLimit or percentualFee`
      );
      continue;
    }
    numberFees.push({
      earningLimit: Number(fee.earningLimit),
      percentualFee: Number(fee.percentualFee),
    });
  }
  return numberFees;
};

interface FeesDetailprops {
  fees: Shop["servicePayments"]["fees"];
  shopId: string;
}
const FeesDetail = (props: FeesDetailprops) => {
  const { fees, shopId } = props;
  const [edit, setEdit] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>({
    defaultValues: {
      fees: feesToString(fees),
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "fees",
    control,
  });

  const [shopUpdateFees, { error, loading }] = useMutation<
    ShopUpdateFeesData,
    ShopUpdateFeesVariables
  >(SHOP_UPDATE_FEES);

  const onSubmit: SubmitHandler<Inputs> = async (inputs) => {
    const { data } = await shopUpdateFees({
      variables: {
        _id: shopId,
        fees: feesToNumber(inputs.fees),
        // fees: numberFees,
      },
    });
    if (!data?.RootShopServicePaymentUpdateFees) {
      log.error(" - shopUpdateFees mutation - something went wrong");
      return;
    }

    reset({
      fees: feesToString(
        data.RootShopServicePaymentUpdateFees.servicePayments.fees
      ),
    });
    setEdit(false);
  };

  const onCancel = () => {
    reset();
    setEdit(false);
  };

  const onAppend = () => {
    append({ earningLimit: "", percentualFee: "" });
  };

  const renderEditButton = () => {
    if (edit) {
      return (
        <Flex direction={"row"} justify="end" mt={8}>
          <Button
            variant={"outline"}
            onClick={onAppend}
            mr={3}
            isLoading={isSubmitting || loading}
          >
            Aggiungi 1
          </Button>
          <Button
            variant={"outline"}
            onClick={onCancel}
            colorScheme={"red"}
            mr={3}
            isLoading={isSubmitting || loading}
          >
            Annulla
          </Button>
          <Button
            variant={"outline"}
            colorScheme="teal"
            type="submit"
            isLoading={isSubmitting || loading}
          >
            Salva
          </Button>
        </Flex>
      );
    }
    return (
      <Flex direction={"row"} justify="end" gap={8} mt={8}>
        <Button variant={"outline"} onClick={() => setEdit(true)}>
          Modifica
        </Button>
      </Flex>
    );
  };

  return (
    <Box w="100%">
      <Card w="100%" h="full" bg="white" p={4}>
        <CardHeader>
          <Heading size="md">Scaglioni di Costo</Heading>
        </CardHeader>
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack
              spacing={4}
              divider={<StackDivider borderColor="gray.200" />}
            >
              {fields.map((field, index) => {
                return (
                  <Flex
                    key={field.id}
                    w="full"
                    direction={"row"}
                    justify="space-between"
                    gap={8}
                    align="end"
                  >
                    <FormInputNumber
                      label="Fino a"
                      // key={field.id}
                      id={`fees.${index}.earningLimit` as const}
                      control={control}
                      error={errors?.fees?.[index]?.earningLimit}
                      rules={{ required: "* campo obbligatorio" }}
                      isDisabled={!edit}
                      textAlign="right"
                      rightElement={
                        <InputRightElement
                          pointerEvents="none"
                          color="gray.400"
                          fontSize="lg"
                          children="€"
                        />
                      }
                    />
                    <FormInputNumber
                      label="Costo"
                      // key={field.id}
                      id={`fees.${index}.percentualFee` as const}
                      control={control}
                      error={errors?.fees?.[index]?.percentualFee}
                      rules={{ required: "* campo obbligatorio" }}
                      isDisabled={!edit}
                      textAlign="right"
                      rightElement={
                        <InputRightElement
                          pointerEvents="none"
                          color="gray.400"
                          fontSize="lg"
                          children="%"
                        />
                      }
                    />
                    <IconButton
                      colorScheme="blue"
                      variant={"outline"}
                      aria-label="Delete"
                      isDisabled={!edit}
                      icon={<DeleteIcon />}
                      onClick={() => {
                        remove(index);
                      }}
                    />
                  </Flex>
                );
              })}
            </VStack>
            {renderEditButton()}
          </form>
        </CardBody>
      </Card>
    </Box>
  );
};

export { FeesDetail };
