import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Flex, Card, Heading, CardBody, Image, Button } from "@chakra-ui/react";

import { ErrorText, FormInputText } from "../../../components/Ui";

import { loginCode, sendAuthCode } from "../../../libs/apollo/auth";
import { userLogged } from "../../../stores/db/appstate";
import { LOG } from "../../../config";

import ImgLogo from "../../../assets/imgs/easypixy-logo.png";

interface Inputs {
  email: string;
  code: string;
}
const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>();

  const [error, setError] = useState<null | string>(null);
  const [opLoad, setOpLoad] = useState<boolean>(false);
  const [loginStep, setLoginStep] = useState<"email" | "code">("email");

  const loginRes = (res: any) => {
    if (res.success) {
      LOG.info("Login Success");
      setOpLoad(false);
      userLogged(res.user);
    } else {
      setOpLoad(false);
      setError(res.msg);
      LOG.warn("Login fallito: " + res.msg);
    }
  };

  const login = (email: string, code: string) => {
    setOpLoad(true);
    setError(null);
    loginCode(email, code).then((res) => {
      loginRes(res);
    });
  };

  const sendCodeRes = (res: { success: boolean; msg: string }) => {
    if (res.success) {
      LOG.info("email Valid");
      setOpLoad(false);
      setLoginStep("code");
    } else {
      setOpLoad(false);
      setError(res.msg);
      LOG.warn("Login fallito: " + res.msg);
    }
  };

  const sendCode = (email: string) => {
    setOpLoad(true);
    setError(null);
    sendAuthCode(email).then((res) => {
      sendCodeRes(res);
    });
  };

  const onSubmitEmail: SubmitHandler<Inputs> = ({ email }) => {
    sendCode(email);
    setLoginStep("code");
  };

  const onSubmitCode: SubmitHandler<Inputs> = (inputs) => {
    login(inputs.email, inputs.code);
    setLoginStep("email");
  };

  const renderError = () => {
    return (
      <ErrorText position="insideElement">
        {error || "Qualcosa è andato storto"}
      </ErrorText>
    );
  };

  const renderForm = () => {
    if (loginStep === "email") {
      return (
        <form key="email" onSubmit={handleSubmit(onSubmitEmail)}>
          <FormInputText
            label="Email"
            id="email"
            placeholder="info@gmail.com"
            error={errors.email}
            register={register("email", { required: "* campo obbligatorio" })}
          />
          <Button
            w="full"
            type="submit"
            mt={8}
            isLoading={opLoad || isSubmitting}
          >
            Invia Codice
          </Button>
        </form>
      );
    }

    if (loginStep === "code") {
      return (
        <form key="code" onSubmit={handleSubmit(onSubmitCode)}>
          <FormInputText
            label="Code"
            id="code"
            placeholder="XXXXX"
            error={errors.code}
            register={register("code", { required: "* campo obbligatorio" })}
          />
          <Button
            w="full"
            type="submit"
            mt={8}
            isLoading={opLoad || isSubmitting}
          >
            Accedi
          </Button>
        </form>
      );
    }
  };

  return (
    <Flex w="100%" h="100vh" py="10%" align={"center"} direction={"column"}>
      <Heading size="lg" textAlign="center" mb={4}>
        Admin Dashboard
      </Heading>
      <Image maxW={"sm"} mb={4} src={ImgLogo} objectFit="contain" />
      <Card w="90%" maxW="md" bg="white">
        <CardBody>{error ? renderError() : renderForm()}</CardBody>
      </Card>
    </Flex>
  );
};

export { Login };
