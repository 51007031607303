import { ReactNode } from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  InputGroup,
} from "@chakra-ui/react";
import {
  FieldError,
  Controller,
  Control,
  RegisterOptions,
} from "react-hook-form";

interface FormInputNumberProps {
  id: string;
  label?: string;
  placeholder?: string;
  error?: FieldError;
  isDisabled?: boolean;
  size?: "sm" | "md" | "lg" | "xs";
  textAlign?: "left" | "center" | "right";
  rules?: RegisterOptions;
  control: Control<any>;
  leftElement?: ReactNode;
  rightElement?: ReactNode;
}
const FormInputNumber = (props: FormInputNumberProps) => {
  const {
    id,
    label,
    placeholder,
    error,
    isDisabled,
    size,
    control,
    rules,
    leftElement,
    rightElement,
    textAlign,
  } = props;
  return (
    <FormControl isInvalid={Boolean(error)}>
      <FormLabel textAlign={textAlign}>{label || null}</FormLabel>
      <Controller
        control={control}
        name={id}
        rules={rules}
        render={({ field: { onChange, onBlur, value, name, ref } }) => (
          <InputGroup>
            {leftElement && leftElement}
            <Input
              key={id}
              ref={ref}
              id={id}
              name={name}
              value={value}
              placeholder={placeholder}
              isDisabled={isDisabled}
              size={size}
              onBlur={onBlur}
              textAlign={textAlign}
              onChange={(event) => {
                event.target.value = event.target.value.replace(/,/i, ".");
                if (isNaN(Number(event.target.value))) {
                  return;
                }
                onChange(event);
              }}
            />
            {rightElement && rightElement}
          </InputGroup>
        )}
      />
      <FormErrorMessage>{error && error.message}</FormErrorMessage>
    </FormControl>
  );
};

export { FormInputNumber };
