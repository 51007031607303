import React from "react";
import { useQuery } from "@apollo/client";
import { Navigate } from "react-router-dom";

import { USER_LOGGED } from "../../stores/queries/appstate";

import { Login } from "./Login";

const Auth = () => {
  const { data } = useQuery(USER_LOGGED);

  if (data?.userLogged) {
    return <Navigate replace to="/" />;
  }

  return (
    <>
      <Login />
    </>
  );
};

export { Auth };
